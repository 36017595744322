/* eslint-disable no-loop-func */
import React, { Component } from "react";
import { Link, Route, Switch, NavLink } from "react-router-dom";
import {
  get_manual_application_detail, get_stock_id_detail,
  update_application_detail, get_application_agents, update_application_detail_complete
} from "../../../actions/dealer/dealerApplicationActions";
import "../../../assets/css/adminEditApplication.css";
import AssetDetail from "../../component/Application/RetailProgramApplications/AssetDetail";
import PersonalAndEmploymentDetail from "../../component/Application/RetailProgramApplications/PersonalAndEmploymentDetail";
import { Dropdown } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { fetchRetailProgramApplication } from "../../component/Application/RetailProgramApplications/ApiHelper";
import PageNotFoundApp from "../404page/PageNotFoundApp";
import ConfirmDeclineRetailProgram from "./ConfirmDeclineRetailProgram";
import ConfirmModelRetailProgram from "./ConfirmModelRetailProgram";
import DeliveryVerification from "../../component/Application/RetailProgramApplications/DeliveryVerification";
import AssignAgent from "../../component/Application/AssignAgent";
import DealerDetail from "../../component/Application/RetailProgramApplications/DealerDetail";
import axios from "../../../_helpers/axiosInterceptorsAdmin";
import { toastr } from 'react-redux-toastr'
import { API_URL } from '../../../../src/constant'

const extractDigitsAndLastTen = (phoneNumber) => {
  const digitsOnly = phoneNumber.replace(/\D/g, '');
  const lastTenDigits = digitsOnly.slice(-10);
  return lastTenDigits;
}

class RetailProgramApplication extends Component {

  constructor(props) {

    super(props);
    this.state = {
      buyerAppId: "",
      isMeDealer: false,
      url: this.props.match.url,
      allAgents: (this.props.agent_listing || []).filter(item =>
        item.aud_user_id?.[0]?.user_id && +item.user_type === 5
      ).map(item => ({
        value: item.aud_user_id?.[0]?.user_id || "",
        label: item.aud_user_id?.[0]?.name || "",
      })),
      basePath: "",

      // ************** Personal Detail >>>

      // general
      applicantFirstName: "",
      applicantLastName: "",
      applicantDateOfBirth: "",
      applicantTelephone: "",
      applicantEmail: "",

      // current Address
      applicantStreetAddress: "",
      applicantStreetAddress2: "",
      applicantCity: "",

      applicantProvince: "",
      selectedProvince: "",

      applicantPostalCode: "",
      applicantCountry: "",
      duration_address_yr: "",
      selectLengthOfStayAtCurrentAddress: "",
      status: "",
      mortgage_amount: "",

      // ************** Employment Information >>>
      employmentCategory: "",  // Employment Category
      selectEmploymentCategory: "",
      employmentStatus: "",  // Employment Status
      selectedEmploymentStatus: "",
      employerName: "",
      occupation: "",
      yearsWithCurrentEmployer: "",
      selectYearsWithCurrentEmployer: "",
      grossMonthlyIncome: 0,

      // Employer Address 
      employerStreetAddress: "",
      employerProvince: "",
      employerEmail: "",
      employerCity: "",
      employerPhone: "",

      // Dealer Detail
      dealerName: "",
      dealerContact: "",
      dealerContactPhone: "",
      dealerContactEmail: "",

      // Frontline Status
      frontlineStatus: "In Review",

      provinces: [
        { value: "AB", label: "Alberta" },
        { value: "BC", label: "British Columbia" },
        { value: "MB", label: "Manitoba" },
        { value: "NB", label: "New Brunswick" },
        { value: "NL", label: "Newfoundland and Labrador" },
        { value: "NS", label: "Nova Scotia" },
        { value: "ON", label: "Ontario" },
        { value: "PE", label: "Prince Edward Island" },
        { value: "QC", label: "Quebec" },
        { value: "SK", label: "Saskatchewan" },
        { value: "NT", label: "Northwest Territories" },
        { value: "NU", label: "Nunavut" },
        { value: "YT", label: "Yukon" }
      ],

      residentialStatuses: [
        { value: "Rent", label: "Rent" },
        { value: "Own", label: "Own" },
        { value: "Live with Parents", label: "Live with Parents" },
        { value: "Other", label: "Other" },
      ],

      employmentCategories: [
        { label: "Employed", value: "Employed" },
        { label: "Unemployed", value: "Unemployed" }
      ],

      yearsWithCurrentEmployerObj: [
        { label: "1 year", value: "1" },
        { label: "2 years", value: "2" },
        { label: "3 years", value: "3" },
        { label: "4 years", value: "4" },
        { label: "5 years or more", value: "5" },
        { label: "6-12 months", value: "6" },
        { label: "less than 6 months", value: "7" }
      ],

      employmentStatusesEmployed: [
        { label: "Full Time", value: "Full Time" },
        { label: "Part Time", value: "Part Time" },
        { label: "Self-Employed", value: "Self-Employed" },
        { label: "Seasonal", value: "Seasonal" },
        { label: "Maternity Leave", value: "Maternity Leave" },
      ],

      employmentStatusesUnemployed: [
        { label: "Disability", value: "Disability" },
        { label: "Retired", value: "Retired" },
        { label: "Social Assistance/Welfare", value: "Social Services" },
        { label: "Unemployment Insurance", value: "Unemployed Insurance" },
      ],

      residentiallengthObj: [
        { label: "1 year", value: "1" },
        { label: "2 years", value: "2" },
        { label: "3 years", value: "3" },
        { label: "4 years", value: "4" },
        { label: "5 years or more", value: "5" },
        { label: "6-12 months", value: "6" },
        { label: "less than 6 months", value: "7" }
      ],

      postApp: [
        {
          id: 1,
          name: "Applicant Details",
          active: true,
          done: false,
          disabled: false,
          pending: false,
          path: `${this.props.match.url}/applicant-detail`,
        },
        {
          id: 2,
          name: "Asset Detail",
          active: true,
          disabled: true,
          done: false,
          pending: false,
          path: `${this.props.match.url}/assets-detail`,
        },
        {
          id: 3,
          name: "Delivery Verification",
          active: true,
          disabled: true,
          done: false,
          pending: false,
          path: `${this.props.match.url}/delivery-verification`,
        }
      ],

      step: 1,
      lastStep: 1,
      applicationStatus: 2,
      created_at: "",

      personal_complete: false,
      employement_complete: false,
      assets_complete: false,
      applicationStatuses: [
        { label: "Credit Unknown", value: "1" },
        { label: "Conditionally approved", value: "2" },
        { label: "Booked", value: "6" },
        { label: "Funded", value: "12" },
        { label: "Declined", value: "4" }
      ],

      selectedApplicationStatus: { value: "12", label: "Conditionally approved" },
      selectApplicationStatus: "",

      mainUrl: this.props.match.url
        .substring(0, this.props.match.url.lastIndexOf("/") + 1)
        .replace(/\/$/, ""),

      errors_personal_detail_form: {
        applicantFirstName: '',
        applicantLastName: '',
        applicantDateOfBirth: '',
        applicantTelephone: '',
        applicantEmail: '',
        applicantStreetAddress: '',
        applicantCity: '',
        applicantProvince: '',
        applicantPostalCode: '',
        applicantCountry: '',
        status: '',
        duration_address_yr: '',

        is_personal_detail_form_validated: false
      },

      validation_personal_detail_form: {
        applicantFirstName: false,
        applicantLastName: false,
        applicantDateOfBirth: false,
        applicantTelephone: false,
        applicantEmail: false,
        applicantStreetAddress: false,
        applicantCity: false,
        applicantProvince: false,
        applicantPostalCode: false,
        applicantCountry: false,
        status: false,
        duration_address_yr: false
      },

      errors_employment_information_form: {
        employmentCategory: '',
        employmentStatus: '',
        yearsWithCurrentEmployer: '',
        monthlyGrossIncome: '',
        is_employment_information_form_validated: false
      },

      validation_employment_information_form: {
        employmentCategory: false,
        employmentStatus: false,
        yearsWithCurrentEmployer: false,
        monthlyGrossIncome: false
      },

      errors_asset_detail_form: {
        vehicleType: '',
        sub_type_of_vehicle: '',
        is_asset_detail_form_validated: false
      },

      validation_asset_detail_form: {
        vehicleType: false,
        sub_type_of_vehicle: false,
      },

      /**
       * forcefull trigger
       */
      trigger_number: 1,
      applicantFirstName: "",
      applicantLastName: "",
      applicantEmailByDealer: "",
      applicantEmail: "",
      applicantTelephone: "",
      application_detail: "",
      firstTypeOfVehicle: "",
      interestedVehicleType: "",
      buyerAppId: "",
      application_type: "",
      application_category: "",
      selectedAgent: "",
      operation_mode: "",
      addAppLication: "",
      applicantSin: "",
      created_at: "",
      employmentStatuses: {},
    };

    this.handleOnChangeDate = this.handleOnChangeDate.bind(this);
    this.updateApplicationStatus = this.updateApplicationStatus.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.componentRef = React.createRef();

  }

  updateApplicationStatus = (newStatus) => {
    this.setState({ selectedApplicationStatus: newStatus });
  };

  getStatusClassName = () => {
    const status = Number(this.state.applicationStatus);
    switch (status) {
      case 2:
        return "status conditionally-approved-dark";
      case 4:
        return "status declined-dark";
      case 6:
        return "status booked-dark";
      case 12:
        return "status funded-dark";
      default:
        return "status conditionally-approved-dark";
    }
  };

  getLabelByValue = (value, object) => {
    for (let i = 0; i < object.length; i++) {
      if (object[i].value === value) {
        return object[i].label;
      }
    }
    return "";
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  select_object = (value) => {
    return (value === undefined || value.length <= 1) ?
      " " :
      {
        value: value ? this.capitalizeFirstLetter(value) : "",
        label: value ? this.capitalizeFirstLetter(value) : ""
      }
  }

  select_object_including_label_by_value = (value, obj) => {
    return (value.length <= 0) ?
      " " :
      {
        value: value ? this.capitalizeFirstLetter(value) : "",
        label: value ? this.getLabelByValue(value, obj) : ""
      }
  }

  handleLocationChange = async () => {
    const { pathname } = this.props.location;
    if (pathname.includes("/admin/retail-program-application")) {
      this.setState({ basePath: "/admin", isMeDealer: false });
    } else if (pathname.includes("/dealer-admin/retail-program-application")) {
      this.setState({ basePath: "/dealer-admin", isMeDealer: true });
    }
  }

  async componentDidMount() {

    await this.handleLocationChange();

    const { location, history } = this.props;
    const pathParts = location.pathname.split('/');
    const validSections = ['applicant-detail', 'assets-detail', 'delivery-verification', 'assign-agent'];
    const lastValidIndex = pathParts.findIndex(part => validSections.includes(part));

    if (lastValidIndex !== -1) {
      // If a valid section is found in the URL
      const newPath = pathParts.slice(0, lastValidIndex + 1).join('/');
      history.push(newPath);
    } else {
      // If no valid section is found, assume 'applicant-detail'
      history.push(`${location.pathname}/applicant-detail`);
    }

    this.props.get_application_agents();

    if (
      !!this.props.match.params?.id
    ) {

      fetchRetailProgramApplication(this.props.match.params.id)

        .then(response => {

          if (response.status === 200) {

            const retailProgramApplicationData = response.data.data;

            const postApp = this.state.postApp;
            postApp[1].disabled = false;
            this.setState({

              ...this.state,
              postApp,

              created_at: retailProgramApplicationData.created_at,
              buyerAppId: this.props.match.params.id,
              frontlineStatus: retailProgramApplicationData.frontline_status,

              errors_personal_detail_form: { is_personal_detail_form_validated: true },
              errors_employment_information_form: { is_employment_information_form_validated: true },
              errors_asset_detail_form: { is_asset_detail_form_validated: true },

              /** 
               * Personal Detail
               * 
               * -- general detail
               */
              applicantFirstName: retailProgramApplicationData.first_name,
              applicantLastName: retailProgramApplicationData.last_name,
              applicantDateOfBirth: new Date(retailProgramApplicationData.date_of_birth + "T00:00:00"),
              applicantTelephone: extractDigitsAndLastTen(retailProgramApplicationData.phone_number),
              applicantEmail: retailProgramApplicationData.email,


              /**
               * -- current address 
               */
              applicantStreetAddress: retailProgramApplicationData.street_address,
              applicantStreetAddress2: retailProgramApplicationData.street_address2,
              applicantCity: retailProgramApplicationData.city,
              applicantProvince: retailProgramApplicationData.province,
              selectedProvince: {
                value: retailProgramApplicationData.province ? this.capitalizeFirstLetter(retailProgramApplicationData.province) : "",
                label: this.getLabelByValue(
                  retailProgramApplicationData.province, this.state.provinces) ?
                  this.getLabelByValue(retailProgramApplicationData.province, this.state.provinces) : ""
              },
              applicantPostalCode: retailProgramApplicationData.postal_code,
              applicantCountry: retailProgramApplicationData.country,

              // issue
              duration_address_yr: retailProgramApplicationData.length_of_stay_at_current_address, // Length of Stay at Current Address
              selectLengthOfStayAtCurrentAddress: this.select_object_including_label_by_value(
                this.clean_null(retailProgramApplicationData.length_of_stay_at_current_address),
                this.state.residentiallengthObj
              ),

              status: this.clean_null(retailProgramApplicationData.residentail_status),
              selectedStatus: this.select_object_including_label_by_value(
                this.clean_null(retailProgramApplicationData.residentail_status),
                this.state.residentialStatuses
              ),
              mortgage_amount: retailProgramApplicationData.mortgage_amount,

              /** 
               * Employment Information
               * 
               * -- general
               */
              employmentCategory: retailProgramApplicationData.employement_status,
              selectEmploymentCategory: this.select_object(retailProgramApplicationData.employement_status),

              employmentStatus: retailProgramApplicationData.type_of_employment,
              selectedEmploymentStatus: this.select_object(retailProgramApplicationData.type_of_employment),

              employerName: retailProgramApplicationData.employer_name,
              occupation: retailProgramApplicationData.occupation,

              yearsWithCurrentEmployer: retailProgramApplicationData.years_with_current_employer,
              selectYearsWithCurrentEmployer: this.select_object_including_label_by_value(
                this.clean_null(retailProgramApplicationData.years_with_current_employer),
                this.state.yearsWithCurrentEmployerObj
              ),

              monthlyGrossIncome: retailProgramApplicationData.gross_monthly_income,

              /**
               * -- employer address 
               */
              employerStreetAddress: retailProgramApplicationData.employer_street_address,
              employerProvince: retailProgramApplicationData.employer_province,
              selectedEmployerProvince: this.select_object_including_label_by_value(
                this.clean_null(retailProgramApplicationData.employer_province),
                this.state.provinces
              ),
              employerEmail: retailProgramApplicationData.employer_email,
              employerCity: retailProgramApplicationData.employer_city,
              employerPhone: extractDigitsAndLastTen(retailProgramApplicationData.employer_telephone),

              /**
               * -- dealer detail
               */
              dealerName: retailProgramApplicationData.dealer.full_name,
              dealerContact: retailProgramApplicationData.owner_detail.primary_contact,
              dealerContactPhone: retailProgramApplicationData.owner_detail.primary_contact_phone,
              dealerContactEmail: retailProgramApplicationData.dealer.email,
              selectedApplicationStatus: retailProgramApplicationData.application_status ? this.state.applicationStatuses.filter(item => item.value === retailProgramApplicationData.application_status)[0] : this.state.selectedApplicationStatus?.label,
              applicationStatus: retailProgramApplicationData.application_status ? retailProgramApplicationData.application_status : 2,
              selectedAgent: retailProgramApplicationData && retailProgramApplicationData.agent
                ? {
                  value: retailProgramApplicationData.agent.id,
                  label: retailProgramApplicationData.agent.full_name
                }
                : "",




              url: this.state.url,
              operation_mode: "edit"  // needs to change...
            })
          }
        }).catch((e) => {
          console.log('API_SERVICE ERROR ', e);
        })
    }

    let currentYear = new Date().getFullYear();
    let earliestYear = 1900;
    let years = [];
    while (currentYear >= earliestYear) {
      years.push({ label: `${currentYear}`, value: currentYear });
      currentYear -= 1;
    }

    // Steps
    let lastStep = 1;
    let assetsDetail = false;
    if (localStorage.getItem("lastStepPostAppEditAdmin")) {
      lastStep = localStorage.getItem("lastStepPostAppEditAdmin");
    }

    this.setState({
      ...this.state,
      yearsDropDown: years,
      lastStep: lastStep,
      assetsDetail: assetsDetail,
      postApp: this.state.postApp.slice().map((item) => {
        if (item.path === this.state.basePath + '/application/addForm/applicant-detail') {
          return {
            ...item,
            active: true,
          };
        }
        return {
          ...item,
          active: false,
        };
      }),
    });

    /**
     * Check the employment category value and set the array for employment status accordingly.
     */
    if (this.state.selectEmploymentCategory === "Employed") {
      this.state.employmentStatuses = this.state.employmentStatusesEmployed;
    } else {
      this.state.employmentStatuses = this.state.employmentStatusesUnemployed;
    }

  }

  handleOnChangeYears = (e, name) => {
    this.personal_detail_validation(name, e.value)
    this.setState({ ...this.state, [name]: e.value })
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "interestRate") {
      const interestRate = value
        .toString()
        .split(",")
        .join("")
        .split("%")
        .join("");
      this.setState({
        ...this.state,
        [name]: interestRate,
      });
    } else {
      this.setState({
        ...this.state,
        [name]: value,
      });
    }
  };

  /** Common functions */
  is_empty(string) {
    if (typeof string === 'string' && string.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  hasMethod(subject, methodName) {
    return subject != null && typeof subject[methodName] == "function";
  }

  /** Personal detail form validation : start */
  personal_detail_validation = (name, value) => {

    let errors = this.state.errors_personal_detail_form;
    let _value = value ? value == null : ''

    const validEmailRegex = RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );

    switch (name) {

      case 'applicantFirstName':
        errors.applicantFirstName =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantFirstName)) {
          this.state.validation_personal_detail_form.applicantFirstName = true
        }
        break;

      case 'applicantLastName':
        errors.applicantLastName =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantLastName)) {
          this.state.validation_personal_detail_form.applicantLastName = true
        }
        break;

      case 'applicantDateOfBirth':
        errors.applicantDateOfBirth =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantFirstName)) {
          this.state.validation_personal_detail_form.applicantDateOfBirth = true
        }
        break;

      case 'applicantTelephone':
        errors.applicantTelephone =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantTelephone)) {
          this.state.validation_personal_detail_form.applicantTelephone = true
        }
        break;

      case 'applicantEmail':
        errors.applicantEmail =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantEmail)) {
          this.state.validation_personal_detail_form.applicantEmail = true
        }
        break;

      case 'applicantStreetAddress':
        errors.applicantStreetAddress =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantStreetAddress)) {
          this.state.validation_personal_detail_form.applicantStreetAddress = true
        }
        break;

      case 'applicantCity':
        errors.applicantCity =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantCity)) {
          this.state.validation_personal_detail_form.applicantCity = true
        }
        break;

      case 'applicantProvince':
        errors.applicantProvince =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantProvince)) {
          this.state.validation_personal_detail_form.applicantProvince = true
        }
        break;

      case 'applicantPostalCode':
        errors.applicantPostalCode =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantPostalCode)) {
          this.state.validation_personal_detail_form.applicantPostalCode = true
        }
        break;

      case 'applicantCountry':
        errors.applicantCountry =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.applicantCountry)) {
          this.state.validation_personal_detail_form.applicantCountry = true
        }
        break;

      case 'duration_address_yr':
        errors.duration_address_yr =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.duration_address_yr)) {
          this.state.validation_personal_detail_form.duration_address_yr = true
        }
        break;

      case 'status':
        errors.status =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.status)) {
          this.state.validation_personal_detail_form.status = true
        }
        break;

      default:
        break;
    }

    this.setState({ errors, [name]: value });

  }

  handleOnChangePersonalDetail = (event) => {
    if (this.hasMethod(event, "preventDefault")) {
      event.preventDefault();
    }
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
    this.personal_detail_validation(name, value)
  }

  validateOnChangePersonalDetail = (name, value) => {
    this.personal_detail_validation(name, value)
  }

  isString(val) {
    return typeof val === 'string' || ((!!val && typeof val === 'object') && Object.prototype.toString.call(val) === '[object String]');
  }

  /** Employment information form validation : start */
  employment_information_validation = (name, value) => {

    let errors = this.state.errors_employment_information_form;
    let _value = (value == null) ? '' : value;
    let employment_statuses = this.state.employmentStatuses;

    switch (name) {

      case 'employmentCategory':
        errors.employmentCategory =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.employmentCategory)) {
          this.state.validation_employment_information_form.employmentCategory = true
        }
        break;

      case 'employmentStatus':
        errors.employmentStatus =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';

        if (this.is_empty(errors.employmentStatus)) {
          this.state.validation_employment_information_form.employmentStatus = true
        } else {
          this.state.validation_employment_information_form.employmentStatus = false
        }
        if (employment_statuses.includes(this.state.employmentStatus)) {
          this.state.validation_employment_information_form.employmentStatus = true
        }
        break;

      case 'yearsWithCurrentEmployer':
        errors.yearsWithCurrentEmployer =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.yearsWithCurrentEmployer)) {
          this.state.validation_employment_information_form.yearsWithCurrentEmployer = true
        } else {
          this.state.validation_employment_information_form.yearsWithCurrentEmployer = false
        }
        if (employment_statuses.includes(this.state.employmentCategory)) {
          this.state.validation_employment_information_form.yearsWithCurrentEmployer = true
        }
        break;

      case 'monthlyGrossIncome':
        errors.monthlyGrossIncome =
          _value.length < 1
            ? 'required field must be filled in.'
            : '';
        if (this.is_empty(errors.monthlyGrossIncome)) {
          this.state.validation_employment_information_form.monthlyGrossIncome = true
        } else {
          this.state.validation_employment_information_form.monthlyGrossIncome = false
        }
        if (employment_statuses.includes(this.state.employmentCategory)) {
          this.state.validation_employment_information_form.monthlyGrossIncome = true
        }
        break;
      default:
        break;
    }
    this.setState({ errors, [name]: value });
  }

  clean_null = (str) => {
    let convert_to_str = String(str);
    let cleanable_words = ["null", "undefined"]
    return cleanable_words.includes(convert_to_str) ? "" : convert_to_str.replace(/^\s+|\s+$/g, '')
  }

  handleOnChangeEmploymentInformation = (event) => {

    if (this.hasMethod(event, "preventDefault")) {
      event.preventDefault();
    }
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });

    this.employment_information_validation(name, value)
  };

  validateOnChangeEmploymentInformation = (name, value) => {
    this.employment_information_validation(name, value)
  }

  handleOnChangeDate = (e, name) => {

    var personal_detail = ['applicantDateOfBirth']

    if (personal_detail.includes(name)) {
      this.personal_detail_validation(name, e)
    }

    this.setState({
      ...this.state,
      [name]: e,
    });

  };

  changeSelect = (e, formName, name) => {

    const personal_detail = ['selectedProvince', 'selectedStatus'];
    let statuses = {};

    if (personal_detail.includes(name)) {
      this.personal_detail_validation(formName, e.value);
    }

    if (['selectEmploymentCategory', 'selectedEmploymentStatus'].includes(name)) {
      this.employment_information_validation(formName, e.value);
      if (name === 'selectEmploymentCategory') {
        statuses = e.value === 'Employed' ? this.state.employmentStatusesEmployed : this.state.employmentStatusesUnemployed;
      }
    }

    this.setState({
      ...this.state,
      [name]: e,
      [formName]: e?.value ?? "",
      ...(name === 'selectEmploymentCategory' && { employmentStatuses: statuses, selectedEmploymentStatus: "" }),
    });

  };

  onClickChangeStep = (prev, step) => {
    this.setState({
      ...this.state,
      step: step,
      coApplicant: true,
      postApp: this.state.postApp.slice().map((item) => {
        if (Number(item.id) === Number(prev)) {
          return {
            ...item,
            active: false,
            disabled: false,
          };
        }
        if (Number(item.id) === Number(step)) {
          return {
            ...item,
            active: true,
            disabled: false,
          };
        }
        // return item
        return {
          ...item,
          active: false,
        };
      }),
    });
  };

  onClickChangeNav = (step) => {
    if (step === 2) {
      localStorage.setItem("coApplicantEditPostApp", true);
      this.setState({
        ...this.state,
        step: step,
        coApplicant: true,
        postApp: this.state.postApp.slice().map((item) => {
          if (Number(item.id) === Number(step)) {
            return {
              ...item,
              active: true,
              disabled: false,
            };
          }
          if (
            Number(item.id) === Number(this.state.step) &&
            Number(this.state.step) !== Number(step)
          ) {
            return {
              ...item,
              active: false,
              disabled: false,
            };
          }
          // return item
          return {
            ...item,
            active: false,
          };
        }),
      });
    } else {
      this.setState({
        ...this.state,
        step: step,
        postApp: this.state.postApp.slice().map((item) => {
          if (Number(item.id) === Number(step)) {
            return {
              ...item,
              active: true,
              disabled: false,
            };
          }
          if (
            Number(item.id) === Number(this.state.step) &&
            Number(this.state.step) !== Number(step)
          ) {
            return {
              ...item,
              active: false,
              disabled: false,
            };
          }
          // return item
          return {
            ...item,
            active: false,
          };
        }),
      });
    }
  };

  handleOnBlurStock = (id) => {
    if (id) {
      this.props.get_stock_id_detail(id);
      this.state.trigger_number = 100;
    }
  };

  changeSelectStatus = (e, prevState) => {

    if (Number(prevState) !== Number(e.value)) {

      window.$("#updateStatusModel").appendTo("#app").addClass('delete-add-modal-container').modal("show");

      this.setState({
        ...this.state,
        tempStatus: e,
      });

    } else {
      return false;
    }
  };

  update_application_status = (e) => {

    /**
     * 1). Update application status through api in backend
     * 2). On success change application status in label
     * 3). Close popup
     */
    let _applicationStatus = e?.value ?? "";

    if (Boolean(_applicationStatus)) {
      // Update status via Axios
      axios.put(`${API_URL}/retail-applications/application/${this.state.buyerAppId}/update-application-status`, {
        application_status: _applicationStatus
      })
        .then(response => {
          // On success change application status in label
          // Close popup
          this.setState({
            ...this.state,
            selectedApplicationStatus: e,
            applicationStatus: e?.value ?? "",
            selectApplicationStatus: e?.value ?? "",
          });

          toastr.error('Success', "Application status updated.")
        })
        .catch(error => {
          // Ask user to try again in toast.
          toastr.error('Error', "Please try again.")
        });
    }
  };

  update_application_decline = (e, declineReason) => {
    const { value } = e || {};
    this.setState({
      selectedApplicationStatus: value || "",
      applicationStatus: value || "",
      selectApplicationStatus: value || "",
      declineReason,
    });
  };

  render() {
    var result = this.props.match.url.split("/");
    var Param = result[result.length - 2];

    return (
      <React.Fragment>

        <Helmet>
          <title>
            {Param
              ? Param === "pending"
                ? "Pending Application"
                : "Active Application"
              : "Application"}
          </title>
          <meta name="description" content="" />
        </Helmet>

        <div className="app-form-main clearfix">

          {this.state.isMeDealer ? (
            <Link className="back" to={this.state.basePath + `/application/general`}>
              Back to applications
            </Link>
          ) : (
            <Link className="back" to={this.state.basePath + `/application/pending`}>
              Back to applications
            </Link>
          )}

          <div className="app-form-side-bar">
            <div className="side-bar-content">

              {/* Right sidebar : navigation started  */}

              <div className="sidebar-head clearfix">

                <div className="frontline-status">
                  Frontline Status: {this.state.frontlineStatus}
                </div>

                <div className="application-status" id="dropdown-basic">
                  <p> Application Status</p>
                  {!this.state.isMeDealer ?
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id=""
                        className={this.getStatusClassName()}
                      >
                        {this.state.selectedApplicationStatus?.label || ""}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {this.state.applicationStatuses.map((item, index) => (
                          <Dropdown.Item
                            onClick={() =>
                              this.changeSelectStatus({ value: item.value, label: item.label })
                            }
                            key={index}
                          >
                            {item.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown> :
                    <div className="dropdown">
                      <button
                        aria-haspopup="true"
                        aria-expanded="false"
                        id=""
                        type="button"
                        className={`status ${this.getStatusClassName()} dropdown-toggle btn btn-success`}
                      >
                        {this.state.selectedApplicationStatus?.label}
                      </button>
                    </div>
                  }

                </div>
              </div>

              {/* Right sidebar : navigation  */}


              <div className="sidebar-nav-holder sidebar-nav-holder-new" >
                <ul className="nav-list">

                  {this.state.postApp.map((item, index) => {
                    let _url = item.path.split('/').at(-1)
                    const url = new URL(window.location.href);
                    const pathname = url.pathname.split('/').at(-1);
                    return (
                      <li
                        className={`nav-list-item ${_url === pathname
                          ? ''
                          : item.disabled
                            ? "not_done"
                            : item.done
                              ? "done"
                              : item.pending
                                ? "already_upload"
                                : "not_done"
                          }`}
                        key={index}
                      >
                        <NavLink
                          className="nav-link"
                          to={`${item.disabled ? "#" : item.path}`}
                          activeClassName={typeof _url !== 'undefined' && _url === pathname ? 'active' : ''}
                          onClick={() => this.onClickChangeNav(item.id)}
                          isActive={() => !item.disabled && !item.done && !item.pending}
                        >
                          <span className="status-circle">
                            {item.disabled || item.done
                              ? <i className="fa fa-check"></i>
                              : item.pending
                                ? <img src="/assets/image/applicationClock.svg" alt="img" className="mb-1" />
                                : <i className="fa fa-check" style={typeof _url !== 'undefined' && _url === pathname ? { color: "#fff" } : {}}></i>}
                          </span>
                          {item.name}
                        </NavLink>
                      </li>
                    )
                  })
                  }

                </ul>

              </div>
              <div className="purpleBoxBottomOuter">
                <DealerDetail
                  dealerName={this.state.dealerName}
                  dealerContact={this.state.dealerContact}
                  dealerContactPhone={this.state.dealerContactPhone}
                  dealerContactEmail={this.state.dealerContactEmail}
                />
              </div>
            </div>
          </div>

          <Switch>
            <Route
              path={`${this.props.match.url}`}
              exact
              name="Buyer Application Detail"
              render={(props) => (
                <PersonalAndEmploymentDetail
                  addAppLication={true}
                  handleOnChange={this.handleOnChange}
                  {...this.state}
                  handleOnChangeYears={this.handleOnChangeYears}
                  handleOnChangeDate={this.handleOnChangeDate}
                  handleOnChangePersonalDetail={this.handleOnChangePersonalDetail}
                  handleOnChangeEmploymentInformation={this.handleOnChangeEmploymentInformation}
                  changeSelect={this.changeSelect}
                  onClickChangeStep={this.onClickChangeStep}
                  {...this.props}
                  handleChangeSlider={this.handleChangeSlider}
                  validateOnChangePersonalDetail={this.validateOnChangePersonalDetail}
                  validateOnChangeEmploymentInformation={this.validateOnChangeEmploymentInformation}
                />
              )}
            />
            <Route
              path={`${this.props.match.url}/applicant-detail`}
              exact
              name="Buyer Application Detail"
              render={(props) => (
                <PersonalAndEmploymentDetail
                  addAppLication={true}
                  handleOnChange={this.handleOnChange}
                  {...this.state}
                  handleOnChangeYears={this.handleOnChangeYears}
                  handleOnChangeDate={this.handleOnChangeDate}
                  handleOnChangePersonalDetail={this.handleOnChangePersonalDetail}
                  handleOnChangeEmploymentInformation={this.handleOnChangeEmploymentInformation}
                  changeSelect={this.changeSelect}
                  onClickChangeStep={this.onClickChangeStep}
                  {...this.props}
                  handleChangeSlider={this.handleChangeSlider}
                  validateOnChangePersonalDetail={this.validateOnChangePersonalDetail}
                  validateOnChangeEmploymentInformation={this.validateOnChangeEmploymentInformation}
                />
              )}
            />
            <Route
              path={`${this.props.match.url}/assets-detail`}
              exact
              name="Assets Detail"
              render={(props) => (
                <AssetDetail
                  applicationId={this.state.buyerAppId}
                  {...this.state}
                  {...this.props}
                />
              )}
            />

            <Route
              path={`${this.props.match.url}/delivery-verification`}
              exact
              name="Assets Detail"
              render={(props) => (
                <>
                  <DeliveryVerification
                    applicationId={this.state.buyerAppId}
                    {...this.state}
                    {...this.props}
                    updateApplicationStatus={this.updateApplicationStatus}
                  />
                </>
              )}
            />

            <Route
              path={`${this.props.match.url}/assign-agent`}
              exact
              name="Assign Agent"
              render={(props) => (
                <AssignAgent
                  addAppLication={false}
                  {...this.state}
                  {...this.props}
                  agent_listing_version={(this.props.agent_listing || []).filter(item =>
                    item.aud_user_id?.[0]?.user_id && +item.user_type === 5
                  ).map(item => ({
                    value: item.aud_user_id?.[0]?.user_id || "",
                    label: item.aud_user_id?.[0]?.name || "",
                  }))}
                  changeSelect={this.changeSelect}
                  onClickChangeStep={this.onClickChangeStep}
                  changeCompleteStatus={this.changeCompleteStatus}
                />
              )}
            />

            <Route name="404 Not Found" path={`${this.props.match.url}/*`} component={PageNotFoundApp} />
          </Switch>

          <ConfirmModelRetailProgram
            tempSelect={this.state.tempStatus}
            update_application_status={this.update_application_status}
            heading={"Application Status"}
            section1={"Do you really want to change application status"}
            section2=""
          />

          <ConfirmDeclineRetailProgram
            declineReason={this.state.declineReason}
            tempSelect={this.state.tempStatus}
            update_application_decline={this.update_application_decline}
            heading={"Application Decline"}
          />

        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    agent_listing: state.dealerAdminReducer.dealerApplicationReducer.agent_listing,
    application_detail: state.dealerAdminReducer.dealerApplicationReducer.application_detail,
    application_manual_detail: state.dealerAdminReducer.dealerApplicationReducer.application_manual_detail,
    stock_detail: state.dealerAdminReducer.dealerApplicationReducer.stock_detail
  };
};

export default connect(mapStateToProps, {
  get_manual_application_detail,
  update_application_detail,
  get_stock_id_detail,
  update_application_detail_complete,
  get_application_agents
})(RetailProgramApplication);