import axios from "axios";
import { API_URL, V1API_FINANCETHAT } from "../../../../constant";

export const fetchRetailProgramApplication = (id) => {
  const url = `${V1API_FINANCETHAT}/applynow-applications/application/${id}/personal-and-employment-detail`;
  const options = {
    method: "GET",
    headers: { "Content-Type": "application/json; charset=utf8" },
    url,
  };
  return axios(options);
};
