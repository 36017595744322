import { useEffect, useRef, useState } from "react";
import {
  useLocation
} from "react-router-dom";
import { history } from "../../../../_helpers";
import { RETAIL_PROGRAM_APP_URL } from "../../../../constant";

import ApplicationHeaderAdmin from "../ApplicationHeaderAdmin";

function AssetDetail(props) {
  const province = props.applicantProvince;

  const [iframeHeight, setIframeHeight] = useState(75);

  const ref = useRef();
  const params = useLocation();
  const id = params.pathname.split('/')[3];

  const navigateToDeliveryNotification = (id) => {
    const url = `/dealer-admin/retail-program-application/${id}/delivery-verification`;
    history.push(url);
  }

  useEffect(() => {

    const receiveMessageFromIframe = (event) => {
      switch (event.data.type) {
        case 'products-updated':
          navigateToDeliveryNotification(id)
          break;
        case 'products-changed':
          const height = 30 + (event.data.products.length * 50);
          setIframeHeight(height);
          break;
      }
    };

    window.addEventListener('message', receiveMessageFromIframe);

    return () => {
      window.removeEventListener('message', receiveMessageFromIframe);
    };
  }, []);

  return (
    <>
      <div className="app-form-content">
        <div className="app-form-content-inner">
          <ApplicationHeaderAdmin {...props} />

          <ul
            className="nav nav-tabs-2 admin-tabs three-items text-left"
            id="formAppTabs"
            role="tablist"
          >
            <li className="nav-item">
              <a
                className="nav-link active pl-5"
                id="delivery-verification-tab"
                data-toggle="tab"
                href="#personal-detail"
                role="tab"
                aria-controls="personal-detail"
              >
                <span className="tabs-text delivery-verification">
                  Asset Detail
                </span>
              </a>
            </li>
          </ul>

          <div className="tab-content" id="formAppTabsContent">
            <iframe ref={ref} width="100%" style={{ height: `${iframeHeight}vh`}} src={`${RETAIL_PROGRAM_APP_URL}/admin/${id}?province=${province}`} ></iframe>
          </div>
        </div>
      </div>
    </>
  );

}
export default AssetDetail;