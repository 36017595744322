import React, { Component } from 'react'
import {
   get_transunion_hard_hit_credit_report
} from '../../../../actions/admin/applicationActions'
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';


class TransunionHardHit extends Component {

   constructor(props) {
      super(props);
      this.state = {
         credit_detail: []
      }
   }

   componentDidMount() {
      const id = this.props.match.params.id;
      this.props.get_transunion_hard_hit_credit_report(id)
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.transunion_hard_hit_credit_report !== this.props.transunion_hard_hit_credit_report && this.props.transunion_hard_hit_credit_report) {
         this.setState({
            ...this.state,
            credit_detail: this.props.transunion_hard_hit_credit_report
         })
      }
   }

   formatKey(key) {
      return key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
   }

   render() {

      const creditScore = this.state.credit_detail?.credit?.[0]?.score ?? 'N/A';

      // Persona Information
      const personalInfo = this.state.credit_detail?.personal_information || {};
      //const personalInfo = {};
      const personalInfoKeys = Object.keys(personalInfo);

      // Names
      const names = this.state.credit_detail?.names ?? [];
      //const names = [];
      const nameKeys = names.length > 0 ? Object.keys(names[0]) : [];

      // Telephones
      const telephones = this.state.credit_detail?.telephones || [];
      const telephone_keys = telephones.length > 0 ? Object.keys(telephones[0]) : [];      
      // const telephones = [];
      // const telephoneKeys = [];


      // Addresses
      const addresses = this.state.credit_detail?.addresses ?? [];
      // const addresses = [];
      const addressKeys = addresses.length > 0 ? Object.keys(addresses[0]) : [];

      // Employments
      const employments = this.state.credit_detail?.employments ?? [];
      // const employments = [];
      const employments_keys = employments.length > 0 ? Object.keys(employments[0]) : [];

      // Credit Summary Items and Credit Summary Detail Items
      const credit_summary_items = this.state.credit_detail?.credit_summary_items ?? [];
      const credit_summary_details_items = this.state.credit_detail?.credit_summary_details_items ?? [];
      // const credit_summary_items = [];
      // const credit_summary_details_items = [];

      // Banking Items and Bankruptcies
      const banking_items = this.state.credit_detail?.banking_items ?? [];
      const bankruptcies_items = this.state.credit_detail?.bankruptcies_items ?? [];
      // const banking_items = [];
      // const bankruptcies_items = [];

      // Legal Items
      const legal_items = this.state.credit_detail?.legal_items ?? [];
      // const legal_items = [];

      // Collection Items
      const collection_items = this.state.credit_detail?.collection_items ?? [];
      // const collection_items = [];

      // Trade Items
      const trade_items = this.state.credit_detail?.trade_items ?? [];
      // const trade_items = [];

      // Consumer Statement Items
      const consumer_statement_items = this.state.credit_detail?.consumer_statement_items ?? "";
      // const consumer_statement_items = "";

      // Inquiry Items
      const inquiry_items = this.state.credit_detail?.inquiry_items ?? [];
      const inquiry_items_keys = inquiry_items.length > 0 ? Object.keys(inquiry_items[0]) : [];
      // const inquiry_items = [];
      // const inquiry_items_keys = [];

      // Miscellaneous Statement Items
      const miscellaneous_statement_items = this.state.credit_detail?.miscellaneous_statement_items ?? "";
      // const miscellaneous_statement_items = "";





      return (
         <React.Fragment>
            <Helmet>
               <title>Credit Report</title>
               <meta name="description" content="" />
            </Helmet>
            <div className='Main-Credit-Report' ref={el => (this.componentRef = el)}>
               <div class="CreditReport-Container">
                  <div class="Admin-CreditReport">
                     {creditScore && creditScore !== 'N/A' ? (
                        <div class="CreditLeft">
                           <h1>{`${this.state.credit_detail?.names?.[0]?.first_name ?? ''} ${this.state.credit_detail?.names?.[0]?.middle_name ?? ''} ${this.state.credit_detail?.names?.[0]?.last_name ?? ''}`} - Credit Report</h1>
                        </div>
                     ) : <div class="CreditLeft"><h5>Unable to fetch the credit report.</h5></div>}
                     <div class="CreditRight">
                        <button><img src="image/sprite-icon/icon-print.svg" alt="" /></button>
                        <h2>CREDIT SCORE: <span>{creditScore}</span></h2>
                     </div>
                  </div>
               </div>

               <div class="Admin-PersonalInfo-Container">
                  <div>

                     <div className="credit-report-print clearfix">
                        <ReactToPrint content={() => this.componentRef}>
                           <PrintContextConsumer>
                              {({ handlePrint }) => (
                                 <button className="btn btn-primary" onClick={handlePrint}>Print Report!</button>
                              )}
                           </PrintContextConsumer>
                        </ReactToPrint>

                     </div>

                     <div className="Admin-InfoInner">

                        <div className="credit-report-print clearfix">
                           <ReactToPrint content={() => this.componentRef}>
                              <PrintContextConsumer>
                                 {({ handlePrint }) => (
                                    <button className="btn btn-primary" onClick={handlePrint}>Print Report!</button>
                                 )}
                              </PrintContextConsumer>
                           </ReactToPrint>
                           <h1>Personal Information</h1>
                        </div>

                        {personalInfoKeys.length > 0 && (
                           <ul>
                              {personalInfoKeys.map((key, index) => (
                                 <li key={index}>
                                    <div className="Admin-PrName">
                                       <h2>{this.formatKey(key)}</h2>
                                       <h3>{personalInfo[key] || ''}</h3>
                                    </div>
                                 </li>
                              ))}
                           </ul>
                        ) || <span className='small-font-with-padding-left-20px'>No Information is found.</span>}

                     </div>

                     <div className="Admin-InfoInner2">
                        <h1>Names</h1>
                        {names.length > 0 && (
                           <ul>
                              {nameKeys.map((key, index) => (
                                 <li>
                                    <div className="Admin-PrName">
                                       <h2>{this.formatKey(key)}</h2>
                                       {names.map((name, index) => (
                                          <h3 key={index}>{name[key]}</h3>
                                       ))}
                                    </div>
                                 </li>
                              ))}
                           </ul>
                        ) || <span className='small-font-with-padding-left-20px'>No Information is found.</span>}
                     </div>

                     <div className="Admin-InfoInner2">
                        <h1>Telephones</h1>
                        {telephones.length > 0 && (
                           <ul>
                              {telephone_keys.map((key, index) => (
                                 <li>
                                    <div className="Admin-PrName">
                                       <h2>{this.formatKey(key)}</h2>
                                       {telephones.map((telephone, index) => (
                                          <h3 key={index}>{telephone[key]}</h3>
                                       ))}
                                    </div>
                                 </li>
                              ))}
                           </ul>
                        ) || <span className='small-font-with-padding-left-20px'>No Information is found.</span>}
                     </div>


                     <div className="Admin-InfoInner2">
                        <h1>Addresses</h1>
                        {addresses.length > 0 && (
                           <ul>
                              {addressKeys.map((key, index) => (
                                 <li>
                                    <div className="Admin-PrName">
                                       <h2>{this.formatKey(key)}</h2>
                                       {addresses.map((address, index) => (
                                          <h3 key={index}>{address[key]}</h3>
                                       ))}
                                    </div>
                                 </li>
                              ))}
                           </ul>
                        ) || <span className='small-font-with-padding-left-20px'>No Information is found.</span>}
                     </div>


                     <div style={{ paddingBottom: '20px' }}>
                        <div class="TradeAcc-Head"><h1>Employments</h1><h2></h2></div>
                        <div className="row">
                           <div className="col-md-12">
                              {employments.length > 0 && (
                                 <table className="custom-table">
                                    <thead>
                                       <tr>
                                          {employments_keys.map((key, index) => (
                                             <th key={index}>{this.formatKey(key)}</th>
                                          ))}
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {employments.map((item, index) => (
                                          <tr key={index}>
                                             {employments_keys.map((key, index) => (
                                                <td key={index} className="small-font">{item[key]}</td>
                                             ))}
                                          </tr>
                                       ))}
                                    </tbody>
                                 </table>
                              ) || <span className='small-font-with-padding-left-20px'>No Information is found.</span>}
                           </div>
                        </div>
                     </div>

                     <div>
                        <div class="TradeAcc-Head"><h1>Credit Summary</h1><h2></h2></div>

                        <div>
                           <div class="TU-Revolving-Head"><h1>Credit Summary Items</h1></div>
                           <div class="row">
                              <div className="col-md-6 mb-4">
                                 {credit_summary_items.length > 0 && (
                                    <>
                                       {credit_summary_items.map((item, index) => (

                                          <div className="box">
                                             <div class="TU-Revolving-Head">
                                                <span style={{ paddingLeft: '20px' }}>Credit Summary Item<h2></h2></span>
                                             </div>
                                             <ul className="tu-list">
                                                {Object.entries(item).map(([key, value]) => (
                                                   <li key={key} className="tu-list-item">
                                                      <div className="tu-key-value-pair">
                                                         <div className="tu-key">{this.formatKey(key)}:</div>
                                                         <div className="tu-value">{value}</div>
                                                      </div>
                                                   </li>
                                                ))}
                                             </ul>
                                          </div>
                                       ))}
                                    </>
                                 ) || <span className='small-font-with-padding-left-20px'>No Information is found.</span>}
                              </div>
                           </div>
                        </div>


                        <div style={{ paddingBottom: '20px' }}>
                           <div class="TU-Revolving-Head"><h1>Credit Summary Detail Items</h1></div>
                           <div class="row">
                              
                                 {credit_summary_details_items.length > 0 && (
                                    <>
                                       {credit_summary_details_items.map((item, index) => (
                                          <div className="col-md-6 mb-4">
                                          <div className="box">
                                             <div class="TU-Revolving-Head">
                                                <span style={{ paddingLeft: '20px' }}>Account Type: {item.type}<h2></h2></span>
                                             </div>
                                             <ul className="tu-list">
                                                {Object.entries(item).map(([key, value]) => (
                                                   <li key={key} className="tu-list-item">
                                                      <div className="tu-key-value-pair">
                                                         <div className="tu-key">{this.formatKey(key)}:</div>
                                                         <div className="tu-value">{value}</div>
                                                      </div>
                                                   </li>
                                                ))}
                                             </ul>
                                          </div>
                                          </div>
                                       ))}
                                    </>
                                 ) || <span className='small-font-with-padding-left-20px'>No Information is found.</span>}
                              
                           </div>
                        </div>
                     </div>

                     <div style={{ paddingBottom: '20px' }}>
                        <div class="TradeAcc-Head"><h1>Banking Details</h1><h2></h2></div>

                        <div class="TU-Revolving-Head"><h1>Banking Items</h1></div>
                        <div class="row">
                           <div className="col-md-6 mb-4">
                              {banking_items.length > 0 && (
                                 <>
                                    {banking_items?.map((item, index) => (
                                       <div className="box">
                                          <div class="TU-Revolving-Head">
                                             <span style={{ paddingLeft: '20px' }}>Subscriber Bank: {item.subscriber_name}<h2></h2></span>
                                          </div>
                                          <ul className="tu-list">
                                             {Object.entries(item).map(([key, value]) => (
                                                // check if key is type then excape it
                                                key === 'subscriber_name' ? '' :
                                                   <li key={key} className="tu-list-item">
                                                      <div className="tu-key-value-pair">
                                                         <div className="tu-key">{this.formatKey(key)}:</div>
                                                         <div className="tu-value">{value}</div>
                                                      </div>
                                                   </li>
                                             ))}
                                          </ul>
                                       </div>
                                    ))
                                    }
                                 </>
                              ) || <span className='small-font-with-padding-left-20px'>No Information is found.</span>}
                           </div>

                           <div class="TU-Revolving-Head"><h1>Bankruptcies</h1></div>
                           <div class="row">
                              <div className="col-md-6 mb-4">
                                 {bankruptcies_items.length > 0 && (
                                    <>
                                       {bankruptcies_items.map((item, index) => (
                                          <>
                                             {bankruptcies_items.length > 0 && (
                                                <div className="box">
                                                   <div class="TU-Revolving-Head">
                                                      <span style={{ paddingLeft: '20px' }}>Trustee Company: {item.trustee_company}<h2></h2></span>
                                                   </div>
                                                   <ul className="tu-list">
                                                      {Object.entries(item).map(([key, value]) => (
                                                         // check if key is type then excape it
                                                         key === 'trustee_company' ? '' :
                                                            <li key={key} className="tu-list-item">
                                                               <div className="tu-key-value-pair">
                                                                  <div className="tu-key">{this.formatKey(key)}:</div>
                                                                  <div className="tu-value">{value}</div>
                                                               </div>
                                                            </li>
                                                      ))}
                                                   </ul>
                                                </div>
                                             )}
                                          </>
                                       ))}
                                    </>
                                 ) || <span className='small-font-with-padding-left-20px'>No Information is found.</span>}
                              </div>
                           </div>
                        </div>
                     </div>

                     <div>
                        <div class="TradeAcc-Head"><h1>Legal Information</h1><h2></h2></div>
                        <div class="TU-Revolving-Head"><h1>Legal Items</h1></div>
                        <div class="row">
                           <div className="col-md-6 mb-4">
                              {legal_items.length > 0 && (
                                 <>
                                    {legal_items?.map((item, index) => (
                                       <div className="box">
                                          <div class="TU-Revolving-Head">
                                             <span style={{ paddingLeft: '20px' }}>Case number: {item.case_number}<h2></h2></span>
                                          </div>
                                          <ul className="tu-list">
                                             {Object.entries(item).map(([key, value]) => (
                                                // check if key is type then excape it
                                                key === 'case_number' ? '' :
                                                   <li key={key} className="tu-list-item">
                                                      <div className="tu-key-value-pair">
                                                         <div className="tu-key">{this.formatKey(key)}:</div>
                                                         <div className="tu-value">{value}</div>
                                                      </div>
                                                   </li>
                                             ))}
                                          </ul>
                                       </div>
                                    ))}
                                 </>
                              ) || <span className='small-font-with-padding-left-20px'>No Information is found.</span>}
                           </div>
                        </div>
                     </div>


                     <div>
                        <div class="TradeAcc-Head"><h1>Collections</h1><h2></h2></div>
                        <div class="TU-Revolving-Head"><h1>Collection Items</h1></div>
                        <div class="row">
                           <div className="col-md-6 mb-4">
                              {collection_items.length > 0 && (
                                 <>
                                    {collection_items?.map((item, index) => (

                                       <div className="box">
                                          <div class="TU-Revolving-Head">
                                             <span style={{ paddingLeft: '20px' }}>Member Name: {item.member_name}<h2></h2></span>
                                          </div>
                                          <ul className="tu-list">
                                             {Object.entries(item).map(([key, value]) => (
                                                // check if key is type then excape it
                                                key === 'member_name' ? '' :
                                                   <li key={key} className="tu-list-item">
                                                      <div className="tu-key-value-pair">
                                                         <div className="tu-key">{this.formatKey(key)}:</div>
                                                         <div className="tu-value">{value}</div>
                                                      </div>
                                                   </li>
                                             ))}
                                          </ul>
                                       </div>
                                    ))}
                                 </>
                              ) || <span className='small-font-with-padding-left-20px'>No Information is found.</span>}
                           </div>
                        </div>
                     </div>

                     <div style={{ paddingBottom: '20px' }}>
                        <div class="TradeAcc-Head"><h1>Trades</h1><h2></h2></div>
                        <div class="TU-Revolving-Head"><h1>Trade items</h1></div>
                        <div class="row">
                           {trade_items.length > 0 && (
                              <>
                                 {trade_items?.map((item, index) => (
                                    <div className="col-md-6 mb-4">
                                       <div className="box">
                                          <div class="TU-Revolving-Head">
                                             <span style={{ paddingLeft: '20px' }}>Account Type: {item.type}<h2></h2></span>
                                          </div>
                                          <ul className="tu-list">
                                             {Object.entries(item).map(([key, value]) => (
                                                // check if key is type then excape it
                                                key === 'type' ? '' :
                                                   <li key={key} className="tu-list-item">
                                                      <div className="tu-key-value-pair">
                                                         <div className="tu-key">{this.formatKey(key)}:</div>
                                                         <div className="tu-value">{value}</div>
                                                      </div>
                                                   </li>
                                             ))}
                                          </ul>
                                       </div>
                                    </div>
                                 ))}
                              </>
                           )}
                        </div>
                     </div>

                     <div style={{ paddingBottom: '10px' }}>
                        <div class="TradeAcc-Head"><h1>Consumer Statements</h1><h2></h2></div>
                        <div class="row">
                           <div className="col-md-12">
                              {consumer_statement_items.length > 0 && (
                                 <span className="small-font">{consumer_statement_items}</span>
                              ) || <span className='small-font-with-padding-left-20px'>No Information is found.</span>}
                           </div>
                        </div>
                     </div>


                     <div>
                        <div class="TradeAcc-Head"><h1>Inquiry Items</h1><h2></h2></div>
                        <div className="row">
                           <div className="col-md-12">
                              {inquiry_items.length > 0 && (
                                 <table className="custom-table">
                                    <thead>
                                       <tr>
                                          {inquiry_items_keys.map((key, index) => (
                                             <th key={index}>{this.formatKey(key)}</th>
                                          ))}
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {inquiry_items.map((item, index) => (
                                          <tr key={index}>
                                             {inquiry_items_keys.map((key, index) => (
                                                <td key={index} className="small-font">{item[key]}</td>
                                             ))}
                                          </tr>
                                       ))}
                                    </tbody>
                                 </table>
                              ) || <span className='small-font-with-padding-left-20px'>No Information is found.</span>}
                           </div>
                        </div>
                     </div>

                     <div style={{ paddingTop: '20px' }}>
                        <div class="TradeAcc-Head"><h1>Miscellaneous Statement Items</h1><h2></h2></div>
                        <div class="row">
                           <div className="col-md-12">
                              {miscellaneous_statement_items.length > 0 && (
                                 <span className="small-font">{miscellaneous_statement_items}</span>
                              ) || <span className='small-font-with-padding-left-20px'>No Information is found.</span>}
                           </div>
                        </div>
                     </div>


                  </div>
               </div>

            </div>
         </React.Fragment >
      )
   }
}
const mapStateToProps = (state) => {
   return {
      transunion_hard_hit_credit_report: state.adminReducer.adminAccounts.applicationReducer.transunion_hard_hit_credit_report,
   }
}
export default connect(mapStateToProps, {
   get_transunion_hard_hit_credit_report
})(TransunionHardHit)

