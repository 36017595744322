import axios from "../../../../_helpers/axiosInterceptorsAdmin";
import { getMonth, getYear } from "date-fns";
import dateFormat from "dateformat";
import $ from "jquery";
import range from "lodash/range";
import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from "react-number-format";
import "react-rangeslider/lib/index.css";
import { toastr } from "react-redux-toastr";
import Select, { components } from "react-select";
import MaskedInput from "react-text-mask";
import { API_URL } from "../../../../constant";
import ApplicationHeaderAdmin from "../ApplicationHeaderAdmin";

/**
 * Manage renderable state for server side validation errors
 */
export class ServerValidation {
  validations = {};
  constructor(validations) {
    this.validations = validations;
  }
  getValidationAsString(key) {
    if (key in this.validations) {
      if (this.validations[key].length > 0) {
        return this.validations[key].join("<br>");
      }
    }
    return null;
  }
  setValidations(validation) {
    this.validations = validation;
  }
}

export const scrollToTop = () => {
  document.querySelector('.app-form-main').scrollIntoView();
}

export const scrollToValidationError = () => {
  setTimeout(() => {
    const firstValidationErrorOnDom = document.querySelector('.text-danger:not(:empty)');
    if (firstValidationErrorOnDom) {
      firstValidationErrorOnDom.scrollIntoView({ block: 'center' })
    }
  }, 200)
}

const validateForm = (obj_validating_keys) => {
  let is_valid = true
  Object.values(obj_validating_keys).forEach((value) => {
    if (value === false) {
      is_valid = false
    }
  });
  return is_valid;
};

class PersonalAndEmploymentDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      screenName: "personal-detail",
      serverValidations: new ServerValidation({}),
      userType: "",
      fieldsDisabled: window.location.href.includes("dealer-admin/retail-program-application")
    };
    $("#personal-detail").removeClass("tabDeactive");
    $("#employment-information").addClass("tabDeactive");
  }

  navigateToAssetDetail = (id) => {
    const { history } = this.props; // Get history from props
    const url = `/admin/retail-program-application/${id}/assets-detail`;
    history.push(url);
  }

  componentDidMount() {

    const userType = localStorage.getItem("user_type");

    this.setState({
      userType: parseInt(userType)
    });

    setTimeout(() => {
      if (this.props.errors_employment_information_form.is_employment_information_form_validated) {
        this.changeScreen("employment-information");
      }
    }, 100);
  }

  extractDigits = (string = '') => {
    if (string.length > 0) {
      return string.replace(/\D/g, '');
    } else {
      return '';
    }
  }

  changeScreen = (para) => {
    if (para === "personal-detail") {
      $("#personal-detail").addClass("active show").removeClass("tabDeactive");
      $("#employment-information").addClass("tabDeactive").removeClass("active show");
    } else {
      $("#personal-detail").removeClass("active show").addClass("tabDeactive");
      $("#employment-information").removeClass("tabDeactive").addClass("active show");
    }
    this.setState({
      ...this.state,
      screenName: para,
    });

    scrollToTop();

  };

  componentDidUpdate(prevProps, prevState) {

    const { updateBuyerApplicationEmployement, updateBuyerApplicationPersonal, assetsDetail, onClickChangeStep, history, url } = this.props;

    if (
      prevProps.updateBuyerApplicationPersonal !== updateBuyerApplicationPersonal && updateBuyerApplicationPersonal !== undefined
    ) {
      $("#personal-detail").toggleClass("tabDeactive active show");
      $("#employment-information").toggleClass("tabDeactive active show");
      this.setState({
        ...this.state,
        screenName: "employment-information",
      });
    }

    if (
      prevProps.updateBuyerApplicationEmployement !== updateBuyerApplicationEmployement &&
      updateBuyerApplicationEmployement !== undefined
    ) {
      if (assetsDetail === true) {
        onClickChangeStep(1, 2);
        history.push(`${url}/assets-detail`);
      } else {
        onClickChangeStep(1, 3);
        history.push(`${url}/delivery-verification`);
      }
    }

  }

  onBack = () => {
    $("#personal-detail").toggleClass("tabDeactive active show");
    $("#employment-information").toggleClass("tabDeactive active show");
    this.setState({
      ...this.state,
      screenName: "personal-detail",
    });
  };

  convert_to_string = (val) => {
    if (typeof val === "undefined") {
      return "";
    } else if (val === null) {
      return "";
    } else if (typeof val === "boolean") {
      return val ? "" : "";
    } else if (typeof val === "string") {
      return val;
    } else {
      return val
    }
  }

  render() {
    const years = range(1900, getYear(new Date()) + 1, 1);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const { Option } = components;

    const renderScrollbar = (props) => {
      return (
        <div style={{ height: 260 }}>
          <Scrollbars>{props.children}</Scrollbars>
        </div>
      );
    };
    
    const renderScrollbar2 = (props) => {
      return (
        <div style={{ height: 115 }}>
          <Scrollbars>{props.children}</Scrollbars>
        </div>
      );
    };

    const renderScrollbarTypeEmployee = (props) => {
      return (
        <div style={{ height: 260 }}>
          <Scrollbars>{props.children}</Scrollbars>
        </div>
      );
    };

    const renderOption = (props) => {
      return (
        <Option {...props}>
          <div>{props.data.label}</div>
        </Option>
      );
    };

    return (

      <React.Fragment>
        <div className="app-form-content">
          <div className="app-form-content-inner">
            <ApplicationHeaderAdmin {...this.props} />
            <ul
              className="nav nav-tabs-2 admin-tabs three-items text-left"
              id="formAppTabs"
              role="tablist"
            >
              <li
                className="nav-item"
                onClick={() => this.changeScreen("personal-detail")}
              >
                <a
                  className={
                    this.state.screenName === "personal-detail"
                      ? "nav-link active pl-5"
                      : "nav-link pl-5"
                  }
                  id="personal-detail-tab"
                  data-toggle="tab"
                  href="#personal-detail"
                  role="tab"
                  aria-controls="personal-detail"
                  aria-selected={
                    this.state.screenName === "personal-detail"
                      ? "true"
                      : "false"
                  }
                >
                  {" "}
                  <span className="tabs-text personal-detail">
                    {" "}
                    Personal Detail{" "}
                  </span>{" "}
                </a>
              </li>
              <li
                className={`nav-item`}
                onClick={() => {
                  if (this.props.errors_personal_detail_form.is_personal_detail_form_validated) {
                    this.changeScreen("employment-information")
                  } else {
                    alert('Please complete personal details')
                  }
                }}
              >

                {
                  this.props.errors_personal_detail_form.is_personal_detail_form_validated ? <a
                    className={
                      this.state.screenName === "employment-information"
                        ? "nav-link active pl-5"
                        : "nav-link pl-5"
                    }
                    id="employment-information-tab"
                    data-toggle="tab"
                    href="#employment-information"
                    role="tab"
                    aria-controls="employment-information"
                    aria-selected={
                      this.state.screenName === "employment-information"
                        ? "true"
                        : "false"
                    }
                  >
                    {" "}
                    <span className="tabs-text employment-information">
                      {" "}
                      Employment Information{" "}
                    </span>{" "}
                  </a> : <a
                    className={
                      this.state.screenName === "employment-information"
                        ? "nav-link active pl-5"
                        : "nav-link pl-5"
                    }
                    href="#employment-information"
                  >
                    {" "}
                    <span className="tabs-text employment-information">
                      {" "}
                      Employment Information{" "}
                    </span>{" "}
                  </a>
                }

              </li>
            </ul>

            <div className="tab-content" id="formAppTabsContent">

              {/* Personal Detail - form */}
              <div
                className="tab-pane fade active show clearfix salman3"
                id="personal-detail"
                role="tabpanel"
                aria-labelledby="personal-detail-tab"
              >
                {this.state.screenName === "personal-detail" ? (
                  <React.Fragment>
                    <div className="forms-head clearfix">
                      {(this.props.applicantFirstName ||
                        this.props.applicantLastName) && (
                          <h1 className="float-left">
                            {`${this.props.applicantFirstName != undefined &&
                              this.props.applicantFirstName != null
                              ? this.props.applicantFirstName || ""
                              : ""
                              } ${this.props.applicantLastName != undefined &&
                                this.props.applicantLastName != null
                                ? this.props.applicantLastName || ""
                                : ""
                              }${this.props.applicantFirstName != undefined &&
                                this.props.applicantFirstName != null
                                ? "'s Details"
                                : ""
                              } `}
                          </h1>
                        )}

                    </div>
                    <div className="form-main">
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label> First Name<em>*</em></label>
                          <input
                            type="text"
                            required
                            className="form-control"
                            name="applicantFirstName"
                            placeholder="First Name"
                            value={this.props.applicantFirstName}
                            onChange={this.props.handleOnChangePersonalDetail}
                            disabled={this.state.fieldsDisabled}
                          />

                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantFirstName}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('applicantFirstName')}</span>

                        </div>
                        <div className="form-field-col">
                          <label> Last Name<em>*</em></label>
                          <input
                            type="text"
                            className="form-control"
                            name="applicantLastName"
                            placeholder="Last Name"
                            value={this.props.applicantLastName}
                            onChange={this.props.handleOnChangePersonalDetail}
                            disabled={this.state.fieldsDisabled}
                          />

                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantLastName}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('applicantLastName')}</span>

                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label> Date of Birth<em>*</em></label>
                          <DatePicker
                            required
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                              decreaseMonth,
                              increaseMonth,
                              prevMonthButtonDisabled,
                              nextMonthButtonDisabled,
                            }) => (
                              <div
                                style={{
                                  margin: 10,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  onClick={decreaseMonth}
                                  disabled={prevMonthButtonDisabled}
                                  type="button"
                                >
                                  {"<"}
                                </button>
                                <select
                                  value={getYear(date)}
                                  onChange={({ target: { value } }) =>
                                    changeYear(value)
                                  }
                                >
                                  {years.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>

                                <select
                                  value={months[getMonth(date)]}
                                  onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                  }
                                >
                                  {months.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                                <button
                                  onClick={increaseMonth}
                                  disabled={nextMonthButtonDisabled}
                                  type="button"
                                >
                                  {">"}
                                </button>
                              </div>
                            )}
                            selected={this.props.applicantDateOfBirth}
                            placeholderText="YYYY-MM-DD"
                            dateFormat="yyyy-MM-dd"
                            onChange={(e) =>
                              this.props.handleOnChangeDate(
                                e,
                                "applicantDateOfBirth"
                              )
                            }
                            maxDate={new Date()}
                            name="applicantDateOfBirth"
                            className="form-control callendar"
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantDateOfBirth}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('applicantDateOfBirth')}</span>
                        </div>
                        <div className="form-field-col">
                          <label>Primary Phone<em>*</em></label>
                          <NumberFormat
                            required
                            className="form-control"
                            format="+1 (###) ###-####"
                            placeholder="+1 (123) 456-7890"
                            onChange={this.props.handleOnChangePersonalDetail}
                            value={this.props.applicantTelephone}
                            name="applicantTelephone"
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantTelephone}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('phone_number')}</span>
                        </div>
                      </div>

                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label> Email Address<em>*</em></label>
                          <input
                            type="email"
                            className="form-control"
                            name="applicantEmail"
                            placeholder="Email"
                            value={this.props.applicantEmail}
                            onChange={this.props.handleOnChangePersonalDetail}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantEmail}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('email')}</span>
                        </div>
                      </div>

                      <h1 className="mt-5 pt-2" style={{ fontSize: "18px" }}>
                        Current Address
                      </h1>

                      <div className="form-field-row clearfix">
                        <div className="form-field-col">
                          <label> Street Address<em>*</em></label>
                          <input
                            type="text"
                            className="form-control"
                            name="applicantStreetAddress"
                            placeholder="Street Address"
                            value={this.props.applicantStreetAddress}
                            onChange={this.props.handleOnChangePersonalDetail}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantStreetAddress}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('address')}</span>
                        </div>
                      </div>

                      <div className="form-field-row clearfix">
                        <div className="form-field-col">
                          <label>Street Address Line 2</label>
                          <input
                            type="text"
                            className="form-control"
                            name="applicantStreetAddress2"
                            placeholder="Street Address Line 2"
                            value={this.props.applicantStreetAddress2}
                            onChange={this.props.handleOnChangePersonalDetail}
                            disabled={this.state.fieldsDisabled}
                          />
                        </div>
                      </div>

                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label> City<em>*</em></label>
                          <input
                            required
                            type="text"
                            id="applicantCity"
                            name="applicantCity"
                            placeholder="City"
                            className="form-control"
                            onChange={this.props.handleOnChangePersonalDetail}
                            value={this.props.applicantCity}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantCity}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('city')}</span>
                        </div>

                        <div className="form-field-col selectSalutation">
                          <label> Province<em>*</em></label>

                          {
                            this.state.fieldsDisabled ?

                              <input
                                required
                                type="text"
                                placeholder="City"
                                className="form-control"
                                value={this.props.provinces.find(item => item.value === this.props.applicantProvince)?.label}
                                disabled={this.state.fieldsDisabled}
                              />

                              :

                              <Select
                                required
                                placeholder="Select Province"
                                id="selectedProvince"
                                name="applicantProvince"
                                options={this.props.provinces}
                                onChange={(e) =>
                                  this.props.changeSelect(
                                    e,
                                    "applicantProvince",
                                    "selectedProvince"
                                  )
                                }
                                value={this.props.selectedProvince}
                                className="react-select-main "
                                classNamePrefix="react-select"
                                components={{
                                  Option: renderOption,
                                  MenuList: renderScrollbar,
                                }}
                                captureMenuScroll={false}
                              />
                          }
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantProvince}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('province')}</span>
                        </div>
                      </div>

                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label> Postal Code<em>*</em></label>
                          <MaskedInput
                            mask={[
                              /[a-zA-Z0-9]/i,
                              /[a-zA-Z0-9]/,
                              /[a-zA-Z0-9]/i,
                              " ",
                              /[a-zA-Z0-9]/,
                              /[a-zA-Z0-9]/i,
                              /[a-zA-Z0-9]/,
                            ]}
                            className="form-control"
                            guide={false}
                            placeholder="12345"
                            id="applicantPostalCode"
                            name="applicantPostalCode"
                            value={this.props.applicantPostalCode}
                            onChange={this.props.handleOnChangePersonalDetail}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantPostalCode}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('postal_code')}</span>
                        </div>

                        <div className="form-field-col">
                          <label> Country<em>*</em></label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            id="applicantCountry"
                            name="applicantCountry"
                            placeholder="Country"
                            value={this.props.applicantCountry}
                            onChange={this.props.handleOnChangePersonalDetail}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.applicantCountry}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('country')}</span>
                        </div>
                      </div>

                      <div className="form-field-row two-col clearfix">

                        <div className="form-field-col selectSalutation">
                          <label>Length of Stay at Current Address<em>*</em></label>
                          {
                            this.state.fieldsDisabled ?

                              <input
                                required
                                type="text"
                                placeholder="City"
                                className="form-control"
                                value={this.props.residentiallengthObj.find(item => item.value === this.props.duration_address_yr)?.label}
                                disabled={this.state.fieldsDisabled}
                              />
                              :
                              <Select
                                required
                                id="selectLengthOfStayAtCurrentAddress"
                                name="duration_address_yr"                                
                                placeholder="Select Residential Length"
                                options={this.props.residentiallengthObj}
                                onChange={(e) =>
                                  this.props.changeSelect(
                                    e,
                                    "duration_address_yr",
                                    "selectLengthOfStayAtCurrentAddress"
                                  )
                                }
                                value={this.props.selectLengthOfStayAtCurrentAddress}
                                className="react-select-main"
                                classNamePrefix="react-select"
                                components={{
                                  Option: renderOption,
                                  MenuList: renderScrollbarTypeEmployee,
                                }}
                                captureMenuScroll={false}
                                disabled={this.state.fieldsDisabled}
                              />
                          }
                          <span className='text-danger'>{this.props.errors_personal_detail_form.duration_address_yr}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('years_at_current_address')}</span>
                        </div>

                        <div className="form-field-col selectSalutation">
                          <label>Residential Status<em>*</em></label>
                          {
                            this.state.fieldsDisabled ?

                              <input
                                required
                                type="text"
                                placeholder="City"
                                className="form-control"
                                value={this.props.status}
                                disabled={this.state.fieldsDisabled}
                              />
                              :

                              <Select
                                required
                                placeholder="Select Status"
                                id="selectedStatus"
                                name="status"
                                options={this.props.residentialStatuses}
                                onChange={(e) =>
                                  this.props.changeSelect(
                                    e,
                                    "status",
                                    "selectedStatus"
                                  )
                                }
                                value={this.props.selectedStatus}
                                className="react-select-main"
                                classNamePrefix="react-select"
                                components={{
                                  Option: renderOption,
                                  MenuList: renderScrollbarTypeEmployee,
                                }}
                                captureMenuScroll={false}
                                disabled={this.state.fieldsDisabled}
                              />
                          }
                          <span className='text-danger'>{this.props.errors_personal_detail_form.status}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('do_you_own_or_rent')}</span>

                        </div>

                      </div>

                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label> Mortgage or rent amount </label>
                          <NumberFormat
                            required
                            className="form-control"
                            value={this.props.mortgage_amount}
                            onChange={this.props.handleOnChangePersonalDetail}
                            allowNegative={false}
                            id="mortgage_amount"
                            name="mortgage_amount"
                            placeholder="Enter Amount"
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_personal_detail_form.mortgage_amount}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('amount')}</span>
                        </div>
                      </div>
                    </div>

                    <div className="footer-btns-holder clearfix">

                      {/**
                       * Application detail -> Personal Detail
                       * if validation successful -> change screen to "Employment Information"
                       * else keep same screen and scroll to first validation error
                       */}
                      {this.props.loading_update === true ? (
                        <button className="btn btn-primary float-right active">
                          {" "}
                          <i
                            class="fa fa-circle-o-notch fa-spin"
                            aria-hidden="true"
                          ></i>{" "}
                        </button>
                      ) : (

                        !this.state.fieldsDisabled ?
                          <button
                            className="btn btn-primary float-right active"
                            onClick={(event) => {
                              scrollToValidationError();
                              event.preventDefault();
                              this.props.validateOnChangePersonalDetail('applicantFirstName', this.props.applicantFirstName);
                              this.props.validateOnChangePersonalDetail('applicantLastName', this.props.applicantLastName);
                              this.props.validateOnChangePersonalDetail('applicantDateOfBirth', this.props.applicantDateOfBirth);
                              this.props.validateOnChangePersonalDetail('applicantTelephone', this.props.applicantTelephone);
                              this.props.validateOnChangePersonalDetail('applicantEmail', this.props.applicantEmail);
                              this.props.validateOnChangePersonalDetail('applicantStreetAddress', this.props.applicantStreetAddress);
                              this.props.validateOnChangePersonalDetail('applicantCity', this.props.applicantCity);
                              this.props.validateOnChangePersonalDetail('applicantProvince', this.props.applicantProvince);
                              this.props.validateOnChangePersonalDetail('applicantPostalCode', this.props.applicantPostalCode);
                              this.props.validateOnChangePersonalDetail('applicantCountry', this.props.applicantCountry);
                              this.props.validateOnChangePersonalDetail('duration_address_yr', this.props.duration_address_yr);
                              this.props.validateOnChangePersonalDetail('status', this.props.status);
                              this.props.validateOnChangePersonalDetail('mortgage_amount', this.props.mortgage_amount);

                              if (validateForm(this.props.validation_personal_detail_form)) {

                                const payload = {
                                  first_name: this.props.applicantFirstName,
                                  last_name: this.props.applicantLastName,
                                  date_of_birth: dateFormat(this.props.applicantDateOfBirth, "yyyy-mm-dd"),
                                  phone_number: this.extractDigits(this.props.applicantTelephone),
                                  email: this.props.applicantEmail,
                                  street_address: this.props.applicantStreetAddress,
                                  street_address2: this.props.applicantStreetAddress2,
                                  city: this.props.applicantCity,
                                  province: this.props.applicantProvince,
                                  postal_code: this.props.applicantPostalCode,
                                  country: this.props.applicantCountry,
                                  length_of_stay_at_current_address: this.props.duration_address_yr,
                                  mortgage_amount: this.props.mortgage_amount ? this.props.mortgage_amount : 0,
                                  residentail_status: this.props.status,
                                  application_status: this.props.applicationStatus,
                                  application_type: 5,
                                  form_identity: 1,
                                  is_just_validation: true,
                                  user: window.localStorage.getItem('userId'),
                                }

                                const applicationId = this.props.match.params.id;

                                axios.put(`${API_URL}/retail-applications/application/${applicationId}/update-personal-and-employment-detail`, payload).then(res => {
                                  toastr.success("Success", "Details successfully updated");
                                  this.changeScreen("employment-information");
                                })

                              } else {
                                this.props.userType === 2 ?
                                  this.props.errors_personal_detail_form.is_personal_detail_form_validated = false
                                  :
                                  this.props.errors_personal_detail_form.is_personal_detail_form_validated = true
                              }
                            }}
                          >
                            {" "}
                            Save & Continue{" "}
                          </button>

                          :

                          <button
                            className="btn btn-primary float-right active"
                            onClick={() => this.changeScreen("employment-information")}
                          >
                            {" "}
                            Continue{" "}
                          </button>

                      )}
                    </div>
                  </React.Fragment>
                ) : null}
              </div>

              {/* Employment Information - form */}
              <div
                className="tab-pane fade"
                id="employment-information"
                role="tabpanel"
                aria-labelledby="employment-information-tab"
              >
                {this.state.screenName === "employment-information" ? (
                  <React.Fragment>

                    <div className="forms-head clearfix">
                      <h1 className="">Employment Information</h1>
                    </div>
                    <div className="form-main">
                      <div className="form-field-row two-col clearfix">

                        <div className="form-field-col selectSalutation">
                          <label> Employment Category<em>*</em></label>

                          {
                            this.state.fieldsDisabled ?

                              <input
                                required
                                type="text"
                                placeholder="City"
                                className="form-control"
                                value={this.props.employmentCategory}
                                disabled={this.state.fieldsDisabled}
                              />
                              :
                              <Select
                                required
                                placeholder="Select Employment Category"
                                id="selectEmploymentCategory"
                                name="employmentCategory"
                                options={this.props.employmentCategories}
                                onChange={(e) =>
                                  this.props.changeSelect(
                                    e,
                                    "employmentCategory",
                                    "selectEmploymentCategory"
                                  )
                                }
                                value={this.props.selectEmploymentCategory}
                                className="react-select-main"
                                classNamePrefix="react-select"
                                components={{
                                  Option: renderOption,
                                  MenuList: renderScrollbar2,
                                }}
                                captureMenuScroll={false}
                                disabled={this.state.fieldsDisabled}
                              />
                          }
                          <span className='text-danger'>{this.props.errors_employment_information_form.employmentCategory}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('employement_category')}</span>
                        </div>

                        <div className="form-field-col selectSalutation">
                          <label> Employment Status<em>*</em></label>

                          {
                            this.state.fieldsDisabled ?

                              <input
                                required
                                type="text"
                                placeholder="City"
                                className="form-control"
                                value={this.props.employmentStatus}
                                disabled={this.state.fieldsDisabled}
                              />
                              :
                              <Select
                                placeholder="Select Type of Employment"
                                id="selectedEmploymentStatus"
                                name="employmentStatus"
                                options={this.props.employmentStatuses}
                                onChange={(e) =>
                                  this.props.changeSelect(
                                    e,
                                    "employmentStatus",
                                    "selectedEmploymentStatus"
                                  )
                                }
                                value={this.props.selectedEmploymentStatus}
                                className="react-select-main"
                                classNamePrefix="react-select"
                                components={{
                                  Option: renderOption,
                                  MenuList: renderScrollbarTypeEmployee,
                                }}
                                captureMenuScroll={false}
                                disabled={this.state.fieldsDisabled}
                              />
                          }
                          <span className='text-danger'>{this.props.errors_employment_information_form.employmentStatus}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('employment_status')}</span>
                        </div>

                      </div>

                      <div className="form-field-row two-col clearfix">

                        <div className="form-field-col">
                          <label> Employer Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="employerName"
                            placeholder="Employee Name"
                            value={this.props.employerName}
                            onChange={this.props.handleOnChangeEmploymentInformation}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_employment_information_form.employerName}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('employer_name')}</span>
                        </div>

                        <div className="form-field-col">
                          <label> Occupation</label>
                          <input
                            type="text"
                            className="form-control"
                            name="occupation"
                            placeholder="Occupation"
                            value={this.props.occupation}
                            onChange={this.props.handleOnChangeEmploymentInformation}
                            disabled={this.state.fieldsDisabled}
                          />
                          <span className='text-danger'>{this.props.errors_employment_information_form.occupation}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('occupation')}</span>
                        </div>

                      </div>


                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col selectSalutation">
                          <label>Years with current employer<em>*</em></label>
                          {
                            this.state.fieldsDisabled ?
                              <input
                                required
                                type="text"
                                placeholder="City"
                                className="form-control"
                                value={this.props.yearsWithCurrentEmployerObj.find(item => item.value === this.props.yearsWithCurrentEmployer)?.label}
                                disabled={this.state.fieldsDisabled}
                              />
                              :
                              <Select
                                required
                                placeholder="Select years with current employer"
                                id="selectYearsWithCurrentEmployer"
                                name="yearsWithCurrentEmployer"
                                options={this.props.yearsWithCurrentEmployerObj}
                                onChange={(e) =>
                                  this.props.changeSelect(
                                    e,
                                    "yearsWithCurrentEmployer",
                                    "selectYearsWithCurrentEmployer"
                                  )
                                }
                                value={this.props.selectYearsWithCurrentEmployer}
                                className="react-select-main"
                                classNamePrefix="react-select"
                                components={{
                                  Option: renderOption,
                                  MenuList: renderScrollbar2,
                                }}
                                captureMenuScroll={false}
                                disabled={this.state.fieldsDisabled}
                              />
                          }
                          <span className='text-danger'>{this.props.errors_employment_information_form.yearsWithCurrentEmployer}</span>
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('years_with_employer')}</span>
                        </div>

                        <div className="form-field-row two-col clearfix">
                          <div className="form-field-col selectSalutation">
                            <label>Net Monthly Income<em>*</em></label>
                            <input
                              type="text"
                              className="form-control"
                              name="monthlyGrossIncome"
                              placeholder="monthlyGrossIncome"
                              value={this.props.monthlyGrossIncome}
                              onChange={this.props.handleOnChangeEmploymentInformation}
                              disabled={this.state.fieldsDisabled}
                            />
                            <span className='text-danger'>{this.props.errors_employment_information_form.monthlyGrossIncome}</span>
                            <span className='text-danger'>{this.state.serverValidations.getValidationAsString('monthly_income')}</span>
                          </div>
                          <div className="form-field-col"></div>
                        </div>
                      </div>

                      <div className="PostApp-Form mt-5">
                        <div className="py-2">
                          <h4>Employer Address </h4>
                        </div>
                        <div className="clearfix ">
                          <label>Employer Street Address</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            id="employerStreetAddress"
                            name="employerStreetAddress"
                            placeholder=""
                            value={this.props.employerStreetAddress}
                            onChange={this.props.handleOnChange}
                            disabled={this.state.fieldsDisabled}
                          />
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col selectSalutation">
                          <label>Employer Province</label>

                          {
                            this.state.fieldsDisabled ?
                              <input
                                required
                                type="text"
                                placeholder="City"
                                className="form-control"
                                value={this.props.provinces.find(item => item.value === this.props.employerProvince)?.label}
                                disabled={this.state.fieldsDisabled}
                              />
                              :
                              <Select
                                required
                                placeholder="Select Province"
                                id="selectedEmployerProvince"
                                name="selectedEmployerProvince"
                                options={this.props.provinces}
                                onChange={(e) =>
                                  this.props.changeSelect(
                                    e,
                                    "employerProvince",
                                    "selectedEmployerProvince"
                                  )
                                }
                                value={this.props.selectedEmployerProvince}
                                className="react-select-main "
                                classNamePrefix="react-select"
                                components={{
                                  Option: renderOption,
                                  MenuList: renderScrollbar,
                                }}
                                captureMenuScroll={false}
                                disabled={this.state.fieldsDisabled}
                              />
                          }
                          <span className='text-danger'>{this.state.serverValidations.getValidationAsString('employer_province')}</span>
                        </div>

                        <div className="form-field-col">
                          <label>Employer Email</label>
                          <input
                            className="form-control"
                            type="email"
                            id="employerEmail"
                            name="employerEmail"
                            placeholder=""
                            value={this.props.employerEmail}
                            onChange={this.props.handleOnChange}
                            disabled={this.state.fieldsDisabled}
                          />
                        </div>
                      </div>
                      <div className="form-field-row two-col clearfix">
                        <div className="form-field-col">
                          <label>Employer City</label>
                          <input
                            type="text"
                            id="employerCity"
                            name="employerCity"
                            className="form-control"
                            placeholder=""
                            value={this.props.employerCity}
                            onChange={this.props.handleOnChange}
                            disabled={this.state.fieldsDisabled}
                          />
                        </div>
                        <div className="form-field-col">
                          <label>Employer Phone</label>
                          <NumberFormat
                            required
                            className="form-control"
                            value={this.props.employerPhone}
                            onChange={this.props.handleOnChange}
                            allowNegative={false}
                            id="employerPhone"
                            name="employerPhone"
                            format="+1-###-###-####"
                            placeholder="+1(123)456-7890"
                            disabled={this.state.fieldsDisabled}
                          />
                        </div>
                      </div>

                    </div>

                    <div className="footer-btns-holder clearfix">
                      {/**
                       * Application detail -> Employment Information
                       * if validation successful -> redirect to Asset Detail}
                       * else keep same screen and scroll to first validation error
                       */}

                      <button
                        className="btn btn-primary float-left"
                        onClick={() => this.changeScreen("personal-detail")}
                      >
                        {" "}
                        Back{" "}
                      </button>
                      {this.props.loading_update === true ? (
                        <button className="btn btn-primary float-right active">
                          {" "}
                          <i
                            class="fa fa-circle-o-notch fa-spin"
                            aria-hidden="true"
                          ></i>{" "}
                        </button>
                      ) : (

                        !this.state.fieldsDisabled ?

                          <button
                            className="btn btn-primary float-right active"
                            onClick={(event) => {

                              event.preventDefault();
                              scrollToValidationError();

                              this.props.validateOnChangeEmploymentInformation('employmentCategory', this.props.employmentCategory);
                              this.props.validateOnChangeEmploymentInformation('employmentStatus', this.props.employmentStatus);
                              this.props.validateOnChangeEmploymentInformation('yearsWithCurrentEmployer', this.props.yearsWithCurrentEmployer);
                              this.props.validateOnChangeEmploymentInformation('monthlyGrossIncome', this.props.monthlyGrossIncome);
                              this.props.validateOnChangeEmploymentInformation('employerStreetAddress', this.props.employerStreetAddress);
                              this.props.validateOnChangeEmploymentInformation('employerProvince', this.props.employerProvince);
                              this.props.validateOnChangeEmploymentInformation('employerEmail', this.props.employerEmail);
                              this.props.validateOnChangeEmploymentInformation('employerCity', this.props.employerCity);
                              this.props.validateOnChangeEmploymentInformation('employerPhone', this.props.employerPhone);

                              if (validateForm(this.props.validation_employment_information_form)) {

                                const payload = {

                                  employement_status: this.props.employmentCategory,
                                  type_of_employment: this.props.employmentStatus,
                                  employer_name: this.props.employerName ? this.props.employerName : "",
                                  occupation: this.props.occupation ? this.props.occupation : "",
                                  years_with_current_employer: this.props.yearsWithCurrentEmployer,
                                  gross_monthly_income: (this.props.monthlyGrossIncome === undefined) ? '' : this.props.monthlyGrossIncome,
                                  employer_street_address: this.convert_to_string(this.props.employerStreetAddress),
                                  employer_province: this.convert_to_string(this.props.employerProvince),
                                  employer_email: this.convert_to_string(this.props.employerEmail),
                                  employer_city: this.convert_to_string(this.props.employerCity),
                                  employer_telephone: this.convert_to_string(this.extractDigits(this.props.employerPhone)),
                                  application_status: this.props.applicationStatus,
                                  application_type: 5,
                                  form_identity: 2,
                                  is_just_validation: true
                                }

                                const applicationId = this.props.match.params.id;
                                payload.is_just_validation = true;
                                axios.put(`${API_URL}/retail-applications/application/${applicationId}/update-personal-and-employment-detail`, payload).then(res => {
                                  toastr.success("", "Details successfully updated");
                                  // redirect to Asset Details:
                                  this.navigateToAssetDetail(applicationId);

                                })

                              } else {
                                this.props.errors_employment_information_form.is_employment_information_form_validated = false;
                              }
                            }}
                          >
                            {" "}
                            Save & Continue{" "}
                          </button>

                          :
                          <button
                            className="btn btn-primary float-right active"
                            onClick={() => this.navigateToAssetDetail(this.props.match.params.id)}
                          >
                            {" "}
                            Continue{" "}
                          </button>

                      )}
                    </div>
                  </React.Fragment>
                ) : null}
              </div>
            </div>

          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default PersonalAndEmploymentDetail;