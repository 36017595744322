import { gaIds } from "../constant";

export const setupGoogleTagManager = (currentPage) => {

  const environment = process.env.REACT_APP_ENV;

  const gtmId = gaIds[currentPage];

  if (environment === "production" && gtmId) {

    console.log("loading google tag manager");

    // Add your Google Tag Manager script to the head
    const script = document.createElement("script");

    // Check if the GTM ID is in the format GTM-XXXXXXX or G-XXXXXXX
    if (gtmId.startsWith("GTM-")) {
      script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
    } else if (gtmId.startsWith("G-")) {
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;
    } else {
      return;
    }    
    script.async = true;
    document.head.appendChild(script);
  }
};