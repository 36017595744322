import React, { memo, useRef } from "react";

const SearchTable = ({
  search,
  handleOnChange,
  placeHolder = "Search Applications",
}) => {
  const searchInputRef = useRef();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleOnChange(searchInputRef.current.value);
    }
  };

  return (
    <div class="input-group">
      <input
        type="text"
        id="search"
        name="search"
        ref={searchInputRef}
        class="form-control border-end-0 customsearch"
        placeholder={placeHolder}
        onKeyDown={(e) => handleKeyDown(e)}
      />
      <span
        class="input-group-text cursor-pointer"
        onClick={() => {
          handleOnChange(searchInputRef.current.value);
        }}
      >
        <i className="fa fa-search"></i>
      </span>
    </div>
  );
};

export default memo(SearchTable, MemoSearchTable);
function MemoSearchTable(prevProps, nextProps) {
  return prevProps.search === nextProps.search;
}
