import React, { Component } from "react";
import axios from '../../../../_helpers/axiosInterceptorsAdmin'
import LoadingOverlay from 'react-loading-overlay';
import { API_URL } from '../../../../constant';
import { toastr } from 'react-redux-toastr';

class PurchaseReceiptTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      receiptData: [],
      isLoading: false,
      actionWhileLoading: 'Loading receipt data...',
      deletingId: null // Initialize deletingId in state
    };
    this.fetchReceiptData = this.fetchReceiptData.bind(this);
  }

  componentDidMount() {
    if (this.props.applicationId) {
      this.fetchReceiptData();
    }
  }

  fetchReceiptData() {
    const { applicationId } = this.props;

    if (!applicationId) {
      console.log("not getting application id");
      return;
    }

    this.setState({ isLoading: true });

    axios.get(`${API_URL}/retail-applications/application/${applicationId}/get-purchase-receipt-detail`)
      .then(response => {
        const data = response.data;
        if (data.data && data.data.length > 0) {
          this.props.updateCustomerPurchaseReceipt(data.data[0].customer_purchase_receipt);
          this.props.updateIsFileUploaded(data.data[0].is_uploaded);
          this.setState({ receiptData: data.data, isLoading: false });
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch(error => {
        console.error('Error fetching receipt data:', error);
        this.setState({ isLoading: false });
      });
  }

  handleDelete = (id) => {
    this.setState({ deletingId: id });

    axios.delete(`${API_URL}/retail-applications/retail-receipt/${id}`)
      .then(response => {
        if (response.status === 204) { // 204 No Content indicates successful deletion
          this.setState(prevState => ({
            receiptData: prevState.receiptData.filter(receipt => receipt.id !== id),
            deletingId: null, // Reset deletingId after successful deletion
          }));
          this.props.onFileDeleteSuccess();
          this.props.updateApplicationStatus({ value: "6", label: "Booked" });
          toastr.success('The uploaded customer receiver receipt has been deleted.');
        } else {
          console.error('Error deleting receipt:', response.statusText);
          this.setState({ deletingId: null }); // Reset deletingId on error
        }
      })
      .catch(error => {
        console.error('Error deleting receipt:', error);
        this.setState({ deletingId: null }); // Reset deletingId on error
      });
  };

  render() {
    const { receiptData, isLoading, actionWhileLoading } = this.state;

    if (!receiptData[0]?.is_uploaded) {
      return null;
    }

    return (
      <LoadingOverlay
        active={isLoading}
        spinner
        text={actionWhileLoading}
      >
        <div className="upload-doc-content purchaseReceiptTable">
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>File Name</th>
                <th>File Type</th>
                <th>Status</th>
                <th>Action</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {receiptData.map(receipt => (
                <tr key={receipt.id}>
                  <td>{receipt.receipt_name}</td>
                  <td>Purchase Receipt</td>
                  <td>{receipt.is_uploaded ? 'Uploaded' : 'Not Uploaded'}</td>
                  <td>
                    {receipt.is_uploaded && (
                      <>
                        <button className="downloadBtnReceipt" onClick={() => window.open(`${API_URL}` + receipt.receipt, '_blank')}>
                          Download
                        </button>
                      </>
                    )}
                  </td>
                  <td>
                    {receipt.is_uploaded && (
                      <>
                        <button className="deleteReceipt" onClick={() => this.handleDelete(receipt.id)}>
                          <img
                            src="/assets/image/trash-icon.svg"
                            alt="trash"
                          />
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </LoadingOverlay>
    );
  }
}

export default PurchaseReceiptTable;
