import React, { useEffect, useState } from "react";
import moment from "moment";
import PendingAccountDealerDetail from "./PendingAccountDealerDetail";
import MaskedInput from "react-text-mask";
import { API_URL } from "../../../../constant";
import NumberFormat from "react-number-format";
import { capitalize, capsProvince } from "./../../../../_helpers/capitalize";
import ReactModal from 'react-modal';
import { Table } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr'
import axios from '../../../../_helpers/axiosInterceptorsAdmin';
import Store from "./../../../../_helpers/store"
import { LOADING_ADMIN_APIS } from "../../../../_constants/constants"
import LoadingOverlay from 'react-loading-overlay';
import { useHistory } from 'react-router-dom';


const PendingAccountReviews = (props) => {

  const history = useHistory();

  const [state, setState] = useState({
    account_id: "",
    user_id: "",
    created_at: null,
    name: "",
    operating_name: "",
    street_address: "",
    city: "",
    province: "",
    postal_code: "",
    phone: "",
    fax: "",
    email: "",
    years_in_business: "",
    no_of_owner: "",
    void_check_path: null,
    interior_business_path: null,
    exterior_business_path: null,
    license_path: null,
    pad_form: null,
    article_of_incorporation: null,
    logo_path: null,
    omviccertificate: null,
    auction_dealer_id: "",
    status: "",
    reason: "",
    note: "",
    modified_at: "",
    country: "",
    photo: null,
    declineText: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    is_active: false,
  });

  const [edit, setEdit] = useState(false);
  const [downloadFiles, setDownloadFiles] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  /* 
  * dealer by operating name found?
  *   - yes
  *     - contact detail found?
  *       - yes
  *         - Is email different? NO ? -> sync dealer with portal (PASS)
  *           note: if same email found means dealer is already created.
  *         - Is email different? YES ? -> dealer is associated with other contact. -> Neither create nor sync (FAIL)
  *       - no 
  *         - is contact by email found? 
  *           - yes
  *             - dealer by email found?
  *               - yes -> Already dealer is created with this email, but operating name is different. -> Neither create nor sync (FAIL)
  *                 note: Delete found dealer by operating name or change the operating name from the request.
  *               - no -> update dealer and contact with submitted details. -> sync dealer with portal (PASS)
  *           - no -> create contact with submitted details and link with dealer -> sync dealer with portal (PASS)
  *             note: update dealer with submitted details.
  *             note: If the dealer contains some contact email (cf_email), then those contact must already be created in the CRM."
  *   - no 
  *     - contact by email found?
  *       - yes
  *         - dealer by email found?
  *           - yes -> contact and dealer are already created. -> sync dealer with portal (PASS)
  *             note: update dealer with submitted details as well as contact.
  *           - no -> create dealer and link with created contact -> sync dealer with portal (PASS)
  *             note: update contact with submitted details.
  *       - no -> create dealer and contact with submitted details -> sync dealer with portal (PASS)    
  */

  /**
   * store data to show in tabs.
   */
  const [dataContactsByEmail, setDataContactsByEmail] = useState(null);
  const [dataDealersByEmail, setDataDealersByEmail] = useState(null);
  const [dataDealersByOperatingName, setDataDealersByOperatingName] = useState(null);

  /**
   * condition to check if contact and dealer is found by email and operating name respectively
   */
  const [isContactByEmailFound, setIsContactByEmailFound] = useState(false);
  const [isDealerByEmailFound, setIsDealerByEmailFound] = useState(false);
  const [isContactExistsInDealerFoundByOperatingName, setIsContactExistsInDealerFoundByOperatingName] = useState(false);
  const [isContactBelongsToDealerWithOperatingName, setIsContactBelongsToDealerWithOperatingName] = useState(false);

  /**
   * Is primary sale account found 
   */
  const [primarySaleAccount, setPrimarySaleAccount] = useState(null);

  /**
   * set contactId and dealerId
   */
  const [contactIdByAPI, setContactIdByAPI] = useState(null);
  const [dealerIdByAPI, setDealerIdByAPI] = useState(null);


  /**
   * set loading and action while loading
   */
  const [isLoading, setIsLoading] = useState(false);
  const [actionWhileLoading, setActionWhileLoading] = useState("loading");

  /**
   * final step of dealer creation on CRM and sync with portal
   */
  const [errorsWhileCreation, setErrorsWhileCreation] = useState(null);

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const searchContactByEmail = () => axios.post(`${API_URL}/freshsales/search-contact-by-email/`, { email: state.email });
  // const searchContactByEmail = () => axios.post(`${API_URL}/freshsales/search-contact-by-email/`, { email: "ft_test_new_11@mail.com" });
  // const searchContactByEmail = () => axios.post(`${API_URL}/freshsales/search-contact-by-email/`, { email: "ontarioselectivemotors@yahoo.com" });

  const searchDealerByOperatingName = () => {
    return axios.post(`${API_URL}/freshsales/search-dealer-by-operating-name/`, {
      operating_name: state.operating_name
    }, config);
  };

  // const searchDealerByOperatingName = () => {
  //   return axios.post(`${API_URL}/freshsales/search-dealer-by-operating-name/`, {
  //     operating_name: "ft_test_operating"
  //   }, config);
  // };

  // 
  // Ontario Selective Motors
  // FT Testing OK
  // ft ggwe

  const openLoader = () => {
    Store.dispatch({
      type: LOADING_ADMIN_APIS,
      status: true
    });
  };

  const closeLoader = () => {
    Store.dispatch({
      type: LOADING_ADMIN_APIS,
      status: false
    });
  };

  const closeModalAndRedirect = () => {
    setModalIsOpen(false);
    history.push('/admin/active-account');
    setIsLoading(false);
  };

  const handleResponsesearchDealerByOperatingName = (response, _contact_id) => {

    let is_dealer_by_operating_name_found = false;
    let is_found_dealer_belongs_to_same_user = true;

    if (response && response.data.success) {

      is_dealer_by_operating_name_found = true;

      /**
       * Dealer Detail (Dealer Detail)
       * 
       * There are two form
       * A. Dealer Detail
       * B. Dealer Contact Detail (Owner Detail)
       */
      const dealer = response.data.data.sales_accounts.sales_accounts[0];
      const queriedEmail = state.email.replace(/\s/g, '');
      const dealerContactEmail = (dealer.custom_field && dealer.custom_field.cf_email) ? dealer.custom_field.cf_email.replace(/\s/g, '') : "";

      /**
       * dealer contact detail to fetch
       * - contact_id_by_operating_name
       * - is_dealer_belongs_to_some_contact
       * - is_operating_name_is_created_with_different_email
       */
      const dealer_contact_detail = response.data?.data?.dealer_contact_detail?.[0];
      const contact_id_by_operating_name = dealer_contact_detail?.contact_id || "";

      /**
       * In the same function, when setting the state, state is not updated immediately. 
       * so we are using local variable to check if contact belongs to dealer with operating name.
       */
      const _isContactExistsInDealerFoundByOperatingName = dealer_contact_detail.is_dealer_belongs_to_some_contact;
      setIsContactExistsInDealerFoundByOperatingName(_isContactExistsInDealerFoundByOperatingName);

      setDealerIdByAPI(dealer.id || null)

      setDataDealersByOperatingName({
        id: dealer.id || "N/A",
        name: dealer.name || "N/A",
        address: dealer.address || "N/A",
        city: dealer.city || "N/A",
        state: dealer.state || "N/A",
        zipcode: dealer.zipcode || "N/A",
        email: dealerContactEmail || "N/A"
      });

      if (_isContactExistsInDealerFoundByOperatingName) {

        /**
         * If dealer belongs to some contact
         */
        if (queriedEmail !== dealerContactEmail) { // Use !== for strict inequality check
          is_found_dealer_belongs_to_same_user = false;
        }

        /**
         * If both contacts are same
         */
        if (parseInt(_contact_id) === contact_id_by_operating_name) {
          setIsContactBelongsToDealerWithOperatingName(true)
        }

      }

    }

    return {
      is_dealer_by_operating_name_found,
      is_found_dealer_belongs_to_same_user
    }
  };

  const searchDealerByEmail = () => axios.post(API_URL + "/freshsales/search-dealer-by-email/", { email: state.email });
  // const searchDealerByEmail = () => axios.post(API_URL + "/freshsales/search-dealer-by-email/", { email: "ft_test_new_11@mail.com" });
  const extractDealerData = (item) => ({
    id: item.id || "",
    name: item.name || "",
    website: item.website || "",
    type: item.type || ""
  });

  /**
   * Handle all sync dealer with portal cases
   * All cases where dealer is found by operating name or email will be handled here.
   */
  const syncDealer = (
    updateContactDetail,
    updateDealerDetail
  ) => {

    setIsLoading(true);
    setActionWhileLoading("syncing");

    let isUpdateContactDetail = updateContactDetail;
    let isUpdateDealerDetail = updateDealerDetail;

    let dealerId = dealerIdByAPI;
    let contactId = contactIdByAPI;

    /**
     * If contact is found by email then update contact detail with dealer ID.
     */
    if (isUpdateContactDetail) {

      /**
       * Update contact detail with dealer ID.
       */
      const requestObject = {
        crm_dealer_id: [
          {
            id: dealerId,
            is_primary: true,
          },
        ],
        email: state.email,
        crm_contact_id: contactId,
        update_contact: updateContactDetail
      };
      const apiEndpoint = `${API_URL}/freshsales/update-contact-on-crm/`;
      setActionWhileLoading("Updating contact...");
      axios
        .post(apiEndpoint, requestObject)
        .then((response) => {

          if (response && response.data.success) {

            /**
             * Update dealer detail with dealer ID.
             */
            if (isUpdateDealerDetail) {

              const requestObject = {
                id: dealerId,
                email: state.email,
              };

              // Update the dealer in CRM with the requestObject as the request body
              const apiEndpoint = `${API_URL}/update-dealer-on-crm/`;
              setActionWhileLoading("Updating dealer...");
              axios
                .post(apiEndpoint, requestObject)
                .then((response) => {

                  if (response && response.data.success) {

                    /** Sync dealer with portal */
                    let user_id = state.user_id;
                    let api_end = API_URL + "/dealer_sync_with_crm/" + user_id + "/";
                    setActionWhileLoading("Syncing dealer with portal...");
                    axios.put(api_end)
                      .then(response => {
                        if (response && response.data.success) {
                          toastr.success('Success', "Dealer synced successfully.")
                          closeModalAndRedirect();
                          setIsLoading(false);
                        } else {
                          if (response.data.errors && response.data.errors.length > 0) {
                            let errors = response.data.errors
                            setErrorsWhileCreation(errors);
                          }
                          setIsLoading(false);
                        }
                      })

                  } else {
                    if (response.data.errors && response.data.errors.length > 0) {
                      let errors = response.data.errors
                      setErrorsWhileCreation(errors);
                      setIsLoading(false);
                    }
                  }
                })
            }
          } else {
            if (response.data.errors && response.data.errors.length > 0) {
              let errors = response.data.errors
              setErrorsWhileCreation(errors);
              setIsLoading(false);
            }
          }
        })
    } else {

      /**
       * Create contact with dealer ID.
       */
      const requestObject = {
        crm_dealer_id: [
          {
            id: dealerId,
            is_primary: true,
          },
        ],
        email: state.email,
        create_contact: true
      };
      const apiEndpoint = `${API_URL}/freshsales/create-contact-on-crm/`;
      setActionWhileLoading("Creating contact...");
      axios
        .post(apiEndpoint, requestObject)
        .then((response) => {

          if (response && response.data.success) {

            /** Sync dealer with portal */
            let user_id = state.user_id;
            let api_end = API_URL + "/dealer_sync_with_crm/" + user_id + "/";
            setActionWhileLoading("Syncing dealer with portal...");
            axios.put(api_end)
              .then(response => {
                if (response && response.data.success) {
                  toastr.success('Success', "Dealer synced successfully.")
                  closeModalAndRedirect();
                  setIsLoading(false);
                } else {
                  if (response.data.errors && response.data.errors.length > 0) {
                    let errors = response.data.errors
                    setErrorsWhileCreation(errors);
                  }
                  setIsLoading(false);
                }
              })
          } else {
            if (response.data.errors && response.data.errors.length > 0) {
              let errors = response.data.errors;
              setErrorsWhileCreation(errors);
            }
            setIsLoading(false);
          }
        });
    }
  }

  const createDealer = (updateContactDetail) => {

    setIsLoading(true);

    let contactId = contactIdByAPI;
    let dealerId = null;

    /** 
     * If contact is found by email, then update contact detail and create dealer with contact detail.
     */
    if (isContactByEmailFound) {

      /** 
       * If contact is found by email 
       * - create dealer without contact detail
       * - update contact detail with dealer ID. 
       */


      /** create dealer without contact detail */
      let api_end = API_URL + "/create_dealer_with_contact/" + state.user_id + "/?isContactByEmailFound=true";
      setActionWhileLoading("Creating dealer...");
      axios.post(api_end)

        .then(response => {
          if (response && response.data.success) {
            dealerId = response.data.data.dealer_id ?? null;

            /**
             * get dealer detail and setDataDealersByOperatingName
             * pending
             */

            /** update contact detail with dealer ID. */
            if (dealerId) {

              const requestObject = {
                crm_dealer_id: [
                  {
                    id: dealerId,
                    is_primary: true,
                  },
                ],
                email: state.email, // Replace this with the actual email value
                crm_contact_id: contactId, // Replace this with the actual contact ID value
                update_contact: updateContactDetail
              };

              const apiEndpoint = `${API_URL}/freshsales/update-contact-on-crm/`;
              setActionWhileLoading("Updating contact...");

              axios
                .post(
                  apiEndpoint,
                  requestObject
                )
                .then((response) => {
                  if (response && response.data.success) {
                    toastr.success('Success', 'Dealer created successfully.');
                    closeModalAndRedirect();
                  } else {
                    if (response.data.errors && response.data.errors.length > 0) {
                      let errors = response.data.errors
                      setErrorsWhileCreation(errors);
                      setIsLoading(false);
                    }
                  }
                })
            } else {
              setIsLoading(false);
            }
          } else {
            /** make array of all errors */
            if (response.data.errors && response.data.errors.length > 0) {
              let errors = response.data.errors
              setErrorsWhileCreation(errors);
            }
            setIsLoading(false);
          }
        })
    } else {

      /**
       * Create dealer with contact information.
       * 
       */
      let api_end = API_URL + "/create_dealer_with_contact/" + state.user_id + "/?isContactByEmailFound=false";
      setActionWhileLoading("Creating dealer...");
      axios.post(api_end)
        .then(response => {
          if (response && response.data.success) {
            toastr.success('Success', "Dealer synced successfully.");
            closeModalAndRedirect();
          } else {
            /** make array of all errors */
            if (response.data.errors && response.data.errors.length > 0) {
              let errors = response.data.errors
              setErrorsWhileCreation(errors);
              setIsLoading(false);
            }
          }
        })
    }
  };

  const handleContactByEmailResponse = (response) => {

    let _contact_id = null;

    if (response.data.success) {
      const data = response.data.data[0] || {};
      const { id, email, name } = data;
      const primary_sales_account_name = data.primary_sales_account_name || "N/A";

      if (id) {

        const contact_obj = {
          id: id,
          email: email,
          name: name,
          primary_sales_account_name: primary_sales_account_name
        };

        setContactIdByAPI(id);
        _contact_id = id;
        setDataContactsByEmail(contact_obj);
        setIsContactByEmailFound(true);

        if (primary_sales_account_name) {
          setPrimarySaleAccount(primary_sales_account_name);
        }

      }
    }

    return _contact_id;

  };

  const handleContactSearchError = (error) => {
    console.error('Error during contact search:', error);
    // Handle the error here if needed
  };

  const resetAllStates = () => {
    setDataContactsByEmail(null);
    setDataDealersByEmail(null);
    setDataDealersByOperatingName(null);
    setIsContactByEmailFound(false);
    setIsDealerByEmailFound(false);
    setErrorsWhileCreation(null);
    setIsContactBelongsToDealerWithOperatingName(false);
  };

  /** Initialize modal */
  const openModal = () => {

    /** reset all states */
    resetAllStates();

    /** check if user is active */


    /** open loader */
    openLoader();

    /** search contact by email */
    searchContactByEmail()
      .then(response => {

        /** consume search contact by email api response */
        let _contact_id = handleContactByEmailResponse(response);

        /** search dealer by email */
        searchDealerByEmail()
          .then((response) => {
            /** consume search dealer by email api response */
            if (response.data.success) {
              const apiResponse = response.data.data;
              const dealerData = apiResponse.map(extractDealerData);
              setDealerIdByAPI(dealerData[0].id || null);
              setDataDealersByEmail(dealerData);
              setIsDealerByEmailFound(dealerData.length > 0);
            }

            /** search dealer by operating name */
            searchDealerByOperatingName()
              .then((response) => {
                /** consume search dealer by operating name api response */
                handleResponsesearchDealerByOperatingName(response, _contact_id);
                setModalIsOpen(true);
                closeLoader();
              })
          });

      })
      .catch(handleContactSearchError);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };


  useEffect(() => {
    if (
      props.update_account_row !== undefined &&
      props.update_account_row !== null
    ) {

      (props.update_account_row || []).map((item) =>
        setState({
          ...state,
          account_id: item.id || "",
          user_id: item?.user_id?.id || "",
          created_at: item.created_at || null,
          name:
            props.accountType === "Dealer"
              ? item.business_name || ""
              : item.name || "",
          operating_name: item.operating_name || "",
          is_active: item.user_id.is_active || false,
          // first_name: props.accountType === 'Dealer' ? '' : item.first_name || '',
          // last_name: props.accountType === 'Dealer' ? '' : item.last_name || '',
          first_name: item.first_name || "",
          last_name: item.last_name || "",
          street_address:
            props.accountType === "Dealer"
              ? item.street_address || ""
              : item.street || "",
          city: item.city || "",
          province: item.province || "",
          postal_code: item.postal_code || "",
          phone:
            props.accountType === "Dealer"
              ? item.phone || ""
              : item.telephone || "",
          phone_number: item?.user_id?.phone_number || "",
          fax: item.fax || "",
          email: item.email || "",
          years_in_business: item.years_in_business || "",
          no_of_owner: item.no_of_owner || "",
          void_check_path:
            item.void_check_path !== undefined && item.void_check_path !== null
              ? API_URL + item.void_check_path
              : null,
          interior_business_path:
            item.interior_business_path !== undefined &&
              item.interior_business_path !== null
              ? API_URL + item.interior_business_path
              : null,
          exterior_business_path:
            item.exterior_business_path !== undefined &&
              item.exterior_business_path !== null
              ? API_URL + item.exterior_business_path
              : null,
          license_path:
            item.license_path !== undefined && item.license_path !== null
              ? API_URL + item.license_path
              : null,

          pad_form:
            item.pad_form !== undefined && item.pad_form !== null
              ? API_URL + item.pad_form
              : null,

          article_of_incorporation:
            item.article_of_incorporation !== undefined && item.article_of_incorporation !== null
              ? API_URL + item.article_of_incorporation
              : null,

          logo_path: item.logo_path || null,
          omviccertificate:
            item.omviccertificate !== undefined &&
              item.omviccertificate !== null
              ? API_URL + item.omviccertificate
              : null,
          auction_dealer_id: item.auction_dealer_id || "",
          status: item.status || "",
          reason: item.reason || "",
          note: item.note || "",
          modified_at: item.modified_at || "",
          country: item.country || "",
          photo: item.photo || null,
        })
      );
    }
  }, [props.update_account_row]);

  useEffect(() => {
    if (
      props.update_account !== undefined &&
      props.update_account !== null &&
      props.update_account === false
    ) {
      setEdit(false);
    }
  }, [props.update_account]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };


  const declineAccount = () => {
    const data = {
      action: "decline",
      reason: state.declineText,
      id: state.user_id,
      update: "account_status",
    };
    props.decline_pending_account(data);


  };
  const provinces = [
    { name: "Alberta", value: "AB" },
    { name: "British Columbia", value: "BC" },
    { name: "Manitoba", value: "MB" },
    { name: "New Brunswick", value: "NB" },
    { name: "Newfoundland and Labrador", value: "NL" },
    { name: "Nova Scotia", value: "NS" },
    { name: "Ontario", value: "ON" },
    { name: "Prince Edward Island", value: "PE" },
    { name: "Quebec", value: "QC" },
    { name: "Saskatchewan", value: "SK" },
    { name: "Northwest Territories", value: "NT" },
    { name: "Nunavut", value: "NU" },
    { name: "Yukon", value: "YT" },
  ];
  const update_private_account = () => {
    var formData = new FormData();
    formData.append("first_name", state.first_name);
    formData.append("last_name", state.last_name);
    formData.append("name", state.name);
    formData.append("street", state.street_address);
    formData.append("city", state.city);
    formData.append("postal_code", state.postal_code);
    formData.append("email", state.email);
    formData.append("telephone", state.phone);
    formData.append("phone_number", state.phone_number);
    if (
      state.user_id !== undefined &&
      state.user_id !== null &&
      state.user_id !== ""
    ) {
      props.update_private_account(formData, state.user_id);
    }
  };
  const update_dealer_account = () => {
    var formData = new FormData();
    formData.append("business_name", state.name);
    formData.append("operating_name", state.operating_name);
    formData.append("street_address", state.street_address);
    formData.append("postal_code", state.postal_code);
    formData.append("city", state.city);
    formData.append("phone", state.phone);
    formData.append("province", state.province);
    formData.append("fax", state.fax);
    formData.append("email", state.email);
    formData.append("years_in_business", state.years_in_business);
    formData.append("phone_number", state.phone_number);
    formData.append("update", "dealer_details");
    if (
      state.user_id !== undefined &&
      state.user_id !== null &&
      state.user_id !== ""
    ) {
      props.update_dealer_account(formData, state.user_id);
    }
  };
  const addFiles = (file) => {
    if (downloadFiles.length === 0) {
      setDownloadFiles([...downloadFiles, file]);
    } else {
      const exist = downloadFiles.filter((item) => item == file);
      if (exist.length > 0) {
        const files = downloadFiles.filter((item) => item !== file);
        setDownloadFiles(files);
      } else {
        setDownloadFiles([...downloadFiles, file]);
      }
    }
  };

  const downloadAllFiles = () => {
    downloadFiles.map((item) => {
      props.downloadFile(item);
    });
  };

  return (
    <React.Fragment>
      <div className="Admin-MainHead">
        <div className="Admin-HeadLeft">
          {/* <h1>Account #{props.loading === true ? (<i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>) : state.account_id}</h1> */}
          <h1>
            Account #
            {props.loading === true ? (
              <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            ) : (
              state.user_id
            )}
          </h1>
          <h2>
            Date Added:{" "}
            {props.loading === true ? (
              <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
            ) : state.created_at !== null ? (
              moment(state.created_at).format("yyyy-MM-DD")
            ) : (
              ""
            )}
          </h2>
        </div>

        <div className="Admin-HeadRight">
          <h3>Pending</h3>
        </div>
      </div>

      <div className="clearfix"></div>

      <div className="admin-account-detail-container adminAccountReview-Panel">
        <div className="Admin-DealerLeft">
          <div className="InnerDealer-Container">
            <div className="InnerDealer-Head">
              <div className="InnerLeft">
                <h1>Account Details</h1>
              </div>

              <div className="InnerRight">
                {/* <h3>ID: <span>{props.loading === true ? (<i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>) : state.account_id}</span></h3> */}
                <h3>
                  ID:{" "}
                  <span>
                    {props.loading === true ? (
                      <i
                        class="fa fa-circle-o-notch fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      state.user_id
                    )}
                  </span>
                </h3>
                <h3>
                  TYPE: <span>{props.accountType}</span>
                </h3>
              </div>
            </div>

            <div className="DealerID-Container">
              {props.accountType !== "Dealer" ? (
                <>
                  <div className="DealerID-List">
                    <div className="LeftCon">
                      <h1>{"First Name"}</h1>
                    </div>
                    <div className="RightCon">
                      <h2>
                        {props.loading === true ? (
                          <i
                            class="fa fa-circle-o-notch fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : edit === true ? (
                          <input
                            type="text"
                            name="first_name"
                            value={state.first_name}
                            onChange={handleOnChange}
                          />
                        ) : (
                          state.first_name
                        )}
                      </h2>
                    </div>
                  </div>

                  <div className="DealerID-List">
                    <div className="LeftCon">
                      <h1>{"Last Name"}</h1>
                    </div>
                    <div className="RightCon">
                      <h2>
                        {props.loading === true ? (
                          <i
                            class="fa fa-circle-o-notch fa-spin"
                            aria-hidden="true"
                          ></i>
                        ) : edit === true ? (
                          <input
                            type="text"
                            name="last_name"
                            value={state.last_name}
                            onChange={handleOnChange}
                          />
                        ) : (
                          state.last_name
                        )}
                      </h2>
                    </div>
                  </div>
                </>
              ) : null}
              {props.accountType === "Dealer" ? (
                <div className="DealerID-List">
                  <div className="LeftCon">
                    <h1>
                      {props.accountType === "Dealer"
                        ? "Business legal name"
                        : "Name"}
                    </h1>
                  </div>
                  <div className="RightCon">
                    <h2>
                      {props.loading === true ? (
                        <i
                          class="fa fa-circle-o-notch fa-spin"
                          aria-hidden="true"
                        ></i>
                      ) : edit === true ? (
                        <input
                          type="text"
                          name="name"
                          value={state.name}
                          onChange={handleOnChange}
                        />
                      ) : (
                        state.name
                      )}
                    </h2>
                  </div>
                </div>
              ) : null}

              {props.accountType !== "Private" ? (
                <div className="DealerID-List">
                  <div className="LeftCon">
                    <h1>Operating name</h1>
                  </div>
                  <div className="RightCon">
                    <h2>
                      {props.loading === true ? (
                        <i
                          class="fa fa-circle-o-notch fa-spin"
                          aria-hidden="true"
                        ></i>
                      ) : edit === true ? (
                        <input
                          type="text"
                          name="operating_name"
                          value={state.operating_name}
                          onChange={handleOnChange}
                        />
                      ) : (
                        state.operating_name
                      )}
                    </h2>
                  </div>
                </div>
              ) : null}

              <div className="DealerID-List">
                <div className="LeftCon">
                  <h1>Street Address</h1>
                </div>
                <div className="RightCon">
                  <h2>
                    {props.loading === true ? (
                      <i
                        class="fa fa-circle-o-notch fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : edit === true ? (
                      <input
                        type="text"
                        name="street_address"
                        value={state.street_address}
                        onChange={handleOnChange}
                      />
                    ) : (
                      state.street_address
                    )}
                  </h2>
                </div>
              </div>

              <div className="DealerID-List">
                <div className="LeftCon">
                  <h1>City</h1>
                </div>
                <div className="RightCon">
                  <h2>
                    {props.loading === true ? (
                      <i
                        class="fa fa-circle-o-notch fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : edit === true ? (
                      <input
                        type="text"
                        name="city"
                        value={capitalize(state.city)}
                        onChange={handleOnChange}
                      />
                    ) : (
                      capitalize(state.city)
                    )}
                  </h2>
                </div>
              </div>
              {/* <input type='text' name='province' value={capsProvince(state.province)} onChange={handleOnChange} /> */}
              {props.accountType === "Dealer" ? (
                <div className="DealerID-List">
                  <div className="LeftCon">
                    <h1>Province</h1>
                  </div>
                  <div className="RightCon">
                    <h2>
                      {props.loading === true ? (
                        <i
                          class="fa fa-circle-o-notch fa-spin"
                          aria-hidden="true"
                        ></i>
                      ) : edit === true ? (
                        <select
                          name="province"
                          onChange={handleOnChange}
                          value={capsProvince(state.province)}
                        >
                          <option value={""}>Select</option>
                          {provinces.map((prov) => (
                            <option value={prov.value}>{prov.name}</option>
                          ))}
                          )
                        </select>
                      ) : (
                        capsProvince(state.province)
                      )}
                    </h2>
                  </div>
                </div>
              ) : null}

              {/* <input type='text' name="postal_code" value={state.postal_code} onChange={handleOnChange} /> */}
              <div className="DealerID-List">
                <div className="LeftCon">
                  <h1>Postal Code</h1>
                </div>
                <div className="RightCon">
                  <h2>
                    {props.loading === true ? (
                      <i
                        class="fa fa-circle-o-notch fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : edit === true ? (
                      <MaskedInput
                        mask={[
                          /[a-zA-Z0-9]/i,
                          /[a-zA-Z0-9]/,
                          /[a-zA-Z0-9]/i,
                          " ",
                          /[a-zA-Z0-9]/,
                          /[a-zA-Z0-9]/i,
                          /[a-zA-Z0-9]/,
                        ]}
                        guide={false}
                        placeholder="A2A 2A2"
                        id="postal_code"
                        name="postal_code"
                        value={state.postal_code}
                        onChange={handleOnChange}
                      />
                    ) : (
                      state.postal_code
                    )}
                  </h2>
                </div>
              </div>

              <div className="DealerID-List">
                <div className="LeftCon">
                  <h1>Email</h1>
                </div>
                <div className="RightCon">
                  <h2>
                    {props.loading === true ? (
                      <i
                        class="fa fa-circle-o-notch fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : edit === true ? (
                      <input
                        type="email"
                        name="email"
                        value={state.email}
                        onChange={handleOnChange}
                      />
                    ) : (
                      state.email
                    )}
                  </h2>
                </div>
              </div>
              {/* <input type='text' name="phone" value={state.phone} onChange={handleOnChange} /> */}
              <div className="DealerID-List">
                <div className="LeftCon">
                  <h1>Telephone</h1>
                </div>
                <div className="RightCon">
                  <h2>
                    {props.loading === true ? (
                      <i
                        class="fa fa-circle-o-notch fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : edit === true ? (
                      <NumberFormat
                        format="+1 (###) ###-####"
                        onChange={handleOnChange}
                        value={state.phone}
                        name="phone"
                      />
                    ) : (
                      state.phone
                    )}
                  </h2>
                </div>
              </div>
              {props.accountType === "Private" ? (
                <div className="DealerID-List">
                  <div className="LeftCon">
                    <h1>Phone</h1>
                  </div>
                  <div className="RightCon">
                    <h2>
                      {props.loading === true ? (
                        <i
                          class="fa fa-circle-o-notch fa-spin"
                          aria-hidden="true"
                        ></i>
                      ) : edit === true ? (
                        <NumberFormat
                          format="+1 (###) ###-####"
                          onChange={handleOnChange}
                          value={state.phone_number}
                          name="phone_number"
                        />
                      ) : (
                        state.phone_number
                      )}
                    </h2>
                  </div>
                </div>
              ) : null}

              {props.accountType === "Dealer" ? (
                <div className="DealerID-List">
                  <div className="LeftCon">
                    <h1>Fax</h1>
                  </div>
                  <div className="RightCon">
                    <h2>
                      {props.loading === true ? (
                        <i
                          class="fa fa-circle-o-notch fa-spin"
                          aria-hidden="true"
                        ></i>
                      ) : edit === true ? (
                        <input
                          type="text"
                          name="fax"
                          value={state.fax}
                          onChange={handleOnChange}
                        />
                      ) : (
                        state.fax
                      )}
                    </h2>
                  </div>
                </div>
              ) : null}

              {/* {props.accountType === 'Dealer' ? (<div className="DealerID-List">
                            <div className="LeftCon"><h1>Years in business</h1></div>
                            <div className="RightCon"><h2>{props.loading === true ? (<i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>) : edit === true ? (<input type='text' name='years_in_business' value={state.years_in_business} onChange={handleOnChange} />) : state.years_in_business}</h2></div>
                        </div>) : null} */}

              <div className="clearfix"></div>

              <div className="Account-EditBtn">
                {edit === true ? (
                  <button
                    type="button"
                    className="newbtn-add"
                    disabled={!edit}
                    onClick={
                      props.accountType === "Dealer"
                        ? update_dealer_account
                        : update_private_account
                    }
                  >
                    {" "}
                    {props.update_account === true ? (
                      <i
                        class="fa fa-circle-o-notch fa-spin"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      "Update"
                    )}{" "}
                  </button>
                ) : (
                  <button type="button" onClick={() => setEdit(!edit)}>
                    {" "}
                    <img src="/assets/image/icon-edit.svg" alt="" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="Admin-DealerRight">
          {props.accountType === "Dealer" ? (
            <div className="InnerDealer-Container">
              <div className="InnerDealer-Head">
                {/* <div className="InnerLeft"><h1>Files Download</h1></div> */}
                <div className="InnerLeft">
                  <h1>Documents</h1>
                </div>

                <div className="InnerRight"></div>
              </div>

              <div className="InnerMark-Container">
                <div className="Admin-DocumetCheck">
                  <label
                    className={
                      state.void_check_path === null
                        ? "DocBtn-Container active"
                        : "DocBtn-Container"
                    }
                  >
                    Void Cheque
                    <input
                      type="checkbox"
                      value={state.void_check_path}
                      disabled={state.void_check_path === null}
                      onClick={() => addFiles(state.void_check_path)}
                    />
                    <span className="DocMark"></span>
                  </label>
                </div>

                <div className="Admin-DocumetCheck">
                  <label
                    className={
                      state.pad_form === null
                        ? "DocBtn-Container active"
                        : "DocBtn-Container"
                    }
                  >
                    PAD Form
                    <input
                      type="checkbox"
                      value={state.pad_form}
                      disabled={state.pad_form === null}
                      onClick={() => addFiles(state.pad_form)}
                    />
                    <span className="DocMark"></span>
                  </label>
                </div>

                <div className="Admin-DocumetCheck">
                  <label
                    className={
                      state.article_of_incorporation === null
                        ? "DocBtn-Container active"
                        : "DocBtn-Container"
                    }
                  >
                    Article of Incorporation
                    <input
                      type="checkbox"
                      value={state.article_of_incorporation}
                      disabled={state.article_of_incorporation === null}
                      onClick={() => addFiles(state.article_of_incorporation)}
                    />
                    <span className="DocMark"></span>
                  </label>
                </div>

                <div className="Admin-DocumetCheck">
                  <label
                    className={
                      state.license_path === null
                        ? "DocBtn-Container active"
                        : "DocBtn-Container"
                    }
                  >
                    Master business License
                    <input
                      type="checkbox"
                      value={state.license_path}
                      disabled={state.license_path === null}
                      onClick={() => addFiles(state.license_path)}
                    />
                    <span className="DocMark"></span>
                  </label>
                </div>

                <div className="Admin-DocumetCheck">
                  <label
                    className={
                      state.interior_business_path === null
                        ? "DocBtn-Container active"
                        : "DocBtn-Container"
                    }
                  >
                    Business interior picture
                    <input
                      type="checkbox"
                      value={state.interior_business_path}
                      disabled={state.interior_business_path === null}
                      onClick={() => addFiles(state.interior_business_path)}
                    />
                    <span className="DocMark"></span>
                  </label>
                </div>

                <div className="Admin-DocumetCheck">
                  <label
                    className={
                      state.exterior_business_path === null
                        ? "DocBtn-Container active"
                        : "DocBtn-Container"
                    }
                  >
                    Business exterior picture
                    <input
                      type="checkbox"
                      value={state.exterior_business_path}
                      disabled={state.exterior_business_path === null}
                      onClick={() => addFiles(state.exterior_business_path)}
                    />
                    <span className="DocMark"></span>
                  </label>
                </div>

                <button
                  type="button"
                  onClick={downloadAllFiles}
                  disabled={downloadFiles.length === 0}
                >
                  Download
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="clearfix"></div>
      {props.accountType === "Dealer" ? (
        <div className="PrinOwner-Container" style={{ width: "943px" }}>
          <PendingAccountDealerDetail {...props} />
        </div>
      ) : null}

      <div className="Reasonfor-Diclined dicline-add-mt">
        <div className="Reasonfor-Head">
          {/* <h1>New reason(s) for declining the listing</h1> */}
          <h1>Reason for declining the registration</h1>
          <textarea
            id="declineText"
            name="declineText"
            onChange={handleOnChange}
            value={state.declineText}
          >
            {" "}
          </textarea>
        </div>
      </div>

      <div className="clearfix"></div>

      <div className="Reasonfor-Btn">

        <button type="button" onClick={openModal}>Approve</button>

        <button
          type="button"
          className="active"
          onClick={declineAccount}
          disabled={props.loading_decline}
        >
          {props.loading_decline === true ? (
            <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
          ) : (
            "Decline"
          )}
        </button>

      </div>


      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Create/Sync Dealer"
      >
        <LoadingOverlay
          active={isLoading}
          spinner
          text={actionWhileLoading}
        >
          <div className="modalReviewAccount">
            <button className="closeButton" onClick={closeModal}>
              <svg fill="#fff" width="30px" height="30px" viewBox="0 0 0.6 0.6" xmlns="http://www.w3.org/2000/svg"><g><path points="13.63 3.65 12.35 2.38 8 6.73 3.64 2.38 2.37 3.65 6.72 8.01 2.38 12.35 3.65 13.63 8 9.28 12.35 13.64 13.63 12.36 9.27 8.01 13.63 3.65" d="M0.511 0.137L0.463 0.089L0.3 0.252L0.137 0.089L0.089 0.137L0.252 0.3L0.089 0.463L0.137 0.511L0.3 0.348L0.463 0.511L0.511 0.463L0.348 0.3L0.511 0.137Z" /></g></svg>
            </button>
            <div className="stepAccountReview">
              <h2 className="color-parrot">Dealer Approval Process (Synchronization with CRM):</h2>
              <p className="font-size-12">This modal is divided into two parts. The first part is to show the details of the dealer creation request and already existing dealer in the CRM. The second part is to show the action to be taken by the admin.</p>
              <div class="row">
                <div class="col-md-8">
                  <h2>Analyses of the dealer creation request:</h2>
                  <h3 className="font-size-14 color-orange">Dealer creation details submitted:</h3>
                  <Table striped bordered className="font-size-12">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Operating Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{state.name}</td>
                        <td>{state.email}</td>
                        <td>{state.operating_name}</td>
                      </tr>
                    </tbody>
                  </Table>

                  <div className="height_30"></div>
                  <h3 className="font-size-14 color-orange">Fetch the contact from the CRM by email:</h3>
                  {dataContactsByEmail ? (
                    <Table striped bordered className="font-size-12">
                      <thead>
                        <tr>
                          <th>Email</th>
                          <th>Related Operating Name</th>
                          <th>Contact ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          <tr>
                            <td>{dataContactsByEmail.email}</td>
                            <td>{dataContactsByEmail.primary_sales_account_name || ""}</td>
                            <td>{dataContactsByEmail.id}</td>
                          </tr>
                        </>
                      </tbody>
                    </Table>

                  ) :
                    <div className="alert alert-info font-size-12 padding-10" role="alert">
                      No contact is found in the CRM with the email ({state.email}).
                    </div>
                  }

                  <div className="height_30"></div>
                  <h3 className="font-size-14 color-orange">Fetch the dealer's details from the CRM by email:</h3>

                  {dataDealersByEmail != null ? (
                    <Table striped bordered className="font-size-12" style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th>Dealer ID</th>
                          <th>Name</th>
                          <th>Website</th>
                          <th>Sale Account</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataDealersByEmail.map((item) => (
                          <tr key={item.id}>
                            {item ? (
                              <>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.website}</td>
                                <td>
                                  {primarySaleAccount === item.name ? (
                                    <span className="badge badge-success">Primary</span>
                                  ) : (
                                    <span className="badge badge-warning">Secondary</span>
                                  )}
                                </td>
                              </>
                            ) : (
                              <td colSpan="4">Contact information not available</td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) :
                    <div className="alert alert-info font-size-12 padding-10" role="alert">
                      No dealer is found in the CRM with the email ({state.email}).
                    </div>
                  }

                  <div className="height_30"></div>
                  <h3 className="font-size-14 color-orange">Fetch the dealer's detail by oprating name from the CRM:</h3>
                  {dataDealersByOperatingName ? (
                    <Table striped bordered className="font-size-12" style={{ width: "100%" }}>
                      <tr>
                        <td>Dealer ID</td>
                        <td>Operating Name</td>
                        <td>Address</td>
                        <td>Email</td>
                      </tr>

                      <tbody>
                        <tr>
                          <td>{dataDealersByOperatingName.id}</td>
                          <td>{dataDealersByOperatingName.name}</td>
                          <td>{dataDealersByOperatingName.address}</td>
                          <td>{dataDealersByOperatingName.email}</td>
                        </tr>
                      </tbody>
                    </Table>
                  ) :
                    <div className="alert alert-info font-size-12 padding-10" role="alert">
                      No dealer is found in the CRM with the operating name ({state.operating_name}).
                    </div>
                  }
                </div>

                <div class="col-md-4">
                  <h2>Action to be taken:</h2>
                  <h3 className="font-size-14 color-orange">Dealer creation request status:</h3>

                  {/* 
                    * dealer by operating name found?
                    *   - yes
                    *     - is contact exists in dealer found by operating name?
                    *       - yes
                    *         - is contact belongs to dealer with operating name?
                    *           - yes
                    *             - is contact by email found?
                    *               - yes
                    *                 - is dealer by email found?
                    *                   - yes
                    *                     - is dealer id found by operating name and dealer id found by email are same?
                    *                       - yes
                    *                         - dealer is syncable with the portal -> [sync dealer].
                    *                        - no
                    *                         - two dealers are found with the same email -> [no action].
                    *                   - no
                    *                     - is contact belongs to dealer with operating name
                    *                       - yes
                    *                         - dealer is syncable with the portal -> [sync dealer].
                    *                       - no
                    *                         - two dealers are found with the same email -> [no action].
                    *               - no
                    *                 - is dealer by email found?
                    *                   - yes
                    *                     - is dealer id found by operating name and dealer id found by email are same?
                    *                       - yes
                    *                         - dealer is syncable with the portal -> [sync dealer].
                    *                       - no
                    *                         - two dealers are found with the same email -> [no action].
                    *                   - no
                    *                     - The operating name is already taken by another dealer -> [no action].
                    *           - no
                    *             - The operating name is associated with another contact in the CRM -> [no action].
                    *       - no
                    *         - is contact by email found?
                    *           - yes
                    *             - is dealer by email found?
                    *               - yes
                    *                 - is dealer id found by operating name and dealer id found by email are same?
                    *                   - yes
                    *                     - dealer is syncable with the portal -> [sync dealer].
                    *                   - no
                    *                     - two dealers are found with the same email -> [no action].
                    *               - no
                    *                 - The operating name is already taken by another dealer -> [no action].
                    *            
                    *           - no
                    *             - is dealer by email found?
                    *               - yes
                    *                 - is dealer id found by operating name and dealer id found by email are same?
                    *                   - yes
                    *                     - dealer is syncable with the portal -> [sync dealer].
                    *                   - no
                    *                     - two dealers are found with the same email. [no action].
                    *               - no
                    *                 - The operating name is already taken by another dealer -> [no action].
                    *   - no
                    *     - is contact by email found?
                    *       - yes
                    *         - is dealer by email found?
                    *           - yes
                    *             - contact and dealer by email are already created. -> [sync dealer].
                    *           - no
                    *             - create dealer and link with created contact -> [create dealer].
                    *         - no
                    *           - is dealer by email found?
                    *             - yes
                    *               - contact and dealer by email are already created. -> [sync dealer].
                    *             - no
                    *               - neither dealer not contact by email is found. -> [create dealer].
                  */}

                  {/**************************************************************** dealer by operating name found? (YES) */}

                  {dataDealersByOperatingName != null ? (

                    /*********** Is contact detail found? (YES) */
                    isContactExistsInDealerFoundByOperatingName ? (
                      <>
                        {
                          /*
                           * contact id received by contact by email and contact id received by dealer operating name are same.
                           * means contact found by email is associated with dealer found by operating name.
                           */
                        }

                        {isContactBelongsToDealerWithOperatingName === false ? (
                          /**
                           * contact by email is found
                           * dealer by email is found
                           * dealer by operating name is found 
                           */

                          <>
                            <div className="alert alert-danger padding-10" role="alert">
                              <p>The operating name ({state.operating_name} {isContactBelongsToDealerWithOperatingName ? "true" : "false"}) raun is associated with another contact in the CRM. So neither creating nor syncing is feasible.</p>
                              <p>note: Delete found dealer by operating name or change the operating name from the request.</p>
                            </div>
                          </>
                        ) : (

                          isContactByEmailFound ?

                            isDealerByEmailFound ? (

                              /**
                               * contact by email is found
                               * dealer by email is found
                               * dealer by operating name is found 
                               */
                              (parseInt(dataDealersByOperatingName.id) === parseInt(dataDealersByEmail[0].id)) ? (
                                <>
                                  <div className="alert alert-info padding-10" role="alert">
                                    <p>The dealer with the email '{state.email}' and operating name '{state.operating_name}' already exists in the CRM.</p>
                                    <p>So the dealer is syncable with the portal. If you agree, please click on the 'Sync Dealer' button below.</p>
                                    {/* update contact: true, update dealer: true */}
                                  </div>
                                  <button type="button" className="btn btn-success" onClick={() => syncDealer(true, true)} disabled={isLoading}>
                                    Sync Dealer
                                  </button>
                                </>
                              ) : (
                                <div className="alert alert-danger padding-10" role="alert">
                                  <ul>
                                    <li>
                                      Two dealers have been identified based on specific details:
                                      <ul>
                                        <li>The first dealer, with dealer ID '{dataDealersByEmail[0].id}', was located through an email search using '{state.email}'.</li>
                                        <li>The second dealer, with dealer ID '{dataDealersByOperatingName.id}', was identified by their operating name, '{state.operating_name}'.</li>
                                      </ul>
                                    </li>
                                    <li>An administrative decision is required to manually resolve one of these dealers in the CRM, as two potential matches have emerged based on the provided information.</li>
                                  </ul>
                                </div>
                              )
                            ) : (
                              /**
                               * contact by email is found
                               * dealer by email is not found
                               * dealer by operating name is found 
                               */
                              <>
                                {isContactBelongsToDealerWithOperatingName && (
                                  <div className="alert alert-info padding-10" role="alert">
                                    <p>The operating name ({state.operating_name}) is associated with contact in the CRM.</p>
                                    <button type="button" className="btn btn-success" onClick={() => syncDealer(true, true)} disabled={isLoading}>
                                      Sync Dealer
                                    </button>
                                  </div>
                                )}
                                {!isContactBelongsToDealerWithOperatingName && (
                                  <div className="alert alert-danger padding-10" role="alert">
                                    <p>The operating name ({state.operating_name}) is associated with another contact in the CRM. So neither creating nor syncing is feasible.</p>
                                    <p>note: Delete found dealer by operating name or change the operating name from the request.</p>
                                  </div>
                                )}
                              </>
                            )
                            :
                            isDealerByEmailFound ? (

                              /**
                               * contact by email is not found
                               * dealer by email is found
                               * dealer by operating name is found 
                               */
                              (parseInt(dataDealersByOperatingName.id) === parseInt(dataDealersByEmail[0].id)) ? (
                                <>
                                  <div className="alert alert-info padding-10" role="alert">
                                    <p>The dealer with the email '{state.email}' and operating name '{state.operating_name}' already exists in the CRM.</p>
                                    <p>So the dealer is syncable with the portal. If you agree, please click on the 'Sync Dealer' button below.</p>
                                    {/* update contact: true, update dealer: true */}
                                  </div>
                                  <button type="button" className="btn btn-success" onClick={() => syncDealer(false, true)} disabled={isLoading}>
                                    Sync Dealer
                                  </button>
                                </>
                              ) : (
                                <div className="alert alert-danger padding-10" role="alert">
                                  <ul>
                                    <li>
                                      Two dealers have been identified based on specific details:
                                      <ul>
                                        <li>The first dealer, with dealer ID '{dataDealersByEmail[0].id}', was located through an email search using '{state.email}'.</li>
                                        <li>The second dealer, with dealer ID '{dataDealersByOperatingName.id}', was identified by their operating name, '{state.operating_name}'.</li>
                                      </ul>
                                    </li>
                                    <li>An administrative decision is required to manually resolve one of these dealers in the CRM, as two potential matches have emerged based on the provided information.</li>
                                  </ul>
                                </div>
                              )

                            ) : (

                              /**
                               * contact by email is not found
                               * dealer by email is not found
                               * dealer by operating name is found 
                               */
                              <>
                                <div className="alert alert-danger padding-10" role="alert">
                                  <p>The operating name ({state.operating_name}) is already taken. So neither creating nor syncing is feasible.</p>
                                  <p>note: Delete found dealer by operating name or change the operating name from the request.</p>
                                </div>
                              </>
                            )
                        )}
                      </>
                    ) : (

                      /**
                       * dealer by operating name found -> no contact is bind with dealer
                       */

                      /*********** Is contact by email found? YES */
                      isContactByEmailFound ? (

                        /*********** Is dealer by email found? YES */
                        isDealerByEmailFound ? (

                          /**
                           * contact by email is found
                           * dealer by email is found
                           * dealer by operating name is found 
                           */

                          (parseInt(dataDealersByOperatingName.id) === parseInt(dataDealersByEmail[0].id)) ? (
                            <>
                              <div className="alert alert-info padding-10" role="alert">
                                <p>The dealer with the email '{state.email}' and operating name '{state.operating_name}' already exists in the CRM.</p>
                                <p>So the dealer is syncable with the portal. If you agree, please click on the 'Sync Dealer' button below.</p>
                                {/* update contact: true, update dealer: true */}
                              </div>
                              <button type="button" className="btn btn-success" onClick={() => syncDealer(true, true)} disabled={isLoading}>
                                Sync Dealer
                              </button>
                            </>
                          ) : (
                            <div className="alert alert-danger padding-10" role="alert">
                              <ul>
                                <li>
                                  Two dealers have been identified based on specific details:
                                  <ul>
                                    <li>The first dealer, with dealer ID '{dataDealersByEmail[0].id}', was located through an email search using '{state.email}'.</li>
                                    <li>The second dealer, with dealer ID '{dataDealersByOperatingName.id}', was identified by their operating name, '{state.operating_name}'.</li>
                                  </ul>
                                </li>
                                <li>An administrative decision is required to manually resolve one of these dealers in the CRM, as two potential matches have emerged based on the provided information.</li>
                              </ul>
                            </div>
                          )
                        ) : (

                          /**
                           * contact by email is found
                           * dealer by email is not found
                           * dealer by operating name is found 
                           */

                          /*********** Is dealer by email found? NO */
                          <>
                            <div className="alert alert-danger padding-10" role="alert">
                              <p>The operating name ({state.operating_name}) is already taken. So neither creating nor syncing is feasible.</p>
                              <p>note: Delete found dealer by operating name or change the operating name from the request.</p>
                            </div>
                          </>

                        )
                      ) : (

                        /** contact by email not found... */
                        isDealerByEmailFound ? (

                          /**
                           * contact by email is not found
                           * dealer by email is found
                           * dealer by operating name is found 
                           */

                          (parseInt(dataDealersByOperatingName.id) === parseInt(dataDealersByEmail[0].id)) ? (
                            <>
                              <div className="alert alert-info padding-10" role="alert">
                                <p>The dealer with the email '{state.email}' and operating name '{state.operating_name}' already exists in the CRM.</p>
                                <p>So the dealer is syncable with the portal. If you agree, please click on the 'Sync Dealer' button below.</p>
                                {/* update contact: true, update dealer: true */}
                              </div>
                              <button type="button" className="btn btn-success" onClick={() => syncDealer(false, true)} disabled={isLoading}>
                                Sync Dealer
                              </button>
                            </>
                          ) : (
                            <div className="alert alert-danger padding-10" role="alert">
                              <ul>
                                <li>
                                  Two dealers have been identified based on specific details:
                                  <ul>
                                    <li>The first dealer, with dealer ID '{dataDealersByEmail[0].id}', was located through an email search using '{state.email}'.</li>
                                    <li>The second dealer, with dealer ID '{dataDealersByOperatingName.id}', was identified by their operating name, '{state.operating_name}'.</li>
                                  </ul>
                                </li>
                                <li>An administrative decision is required to manually resolve one of these dealers in the CRM, as two potential matches have emerged based on the provided information.</li>
                              </ul>
                            </div>
                          )

                        ) : (

                          /**
                           * contact by email is not found
                           * dealer by email is not found
                           * dealer by operating name is found 
                           */

                          <>
                            <div className="alert alert-danger padding-10" role="alert">
                              <p>The operating name ({state.operating_name}) is already taken. So neither creating nor syncing is feasible.</p>
                              <p>note: Delete found dealer by operating name or change the operating name from the request.</p>
                            </div>
                          </>
                        )
                      )
                    )
                  ) : (
                    /**************************************************************** dealer by operating name found? (NO) */

                    /* Is contact by email found? YES */
                    isContactByEmailFound ? (

                      /* Is dealer by email found? YES */
                      isDealerByEmailFound ? (

                        /**
                         * contact by email is not found
                         * dealer by email is not found
                         * dealer by operating name is not found 
                         */

                        <>
                          <div className="alert alert-info padding-10" role="alert">
                            <p>The contact with the email '{state.email}' and dealer with email '{state.email}' already exists in the CRM.</p>
                            <p>So the dealer is syncable with the portal. If you agree, please click on the 'Sync Dealer' button below.</p>
                            <p>note: Dealer with submitted details as well as contact will be updated.</p>
                            {/* update contact: true, update dealer: true */}
                          </div>
                          <button type="button" className="btn btn-success" onClick={() => syncDealer(true, true)} disabled={isLoading}>
                            Sync Dealer
                          </button>
                        </>
                      ) : (
                        /**
                         * contact by email is found
                         * dealer by email is not found
                         * dealer by operating name is not found 
                         */
                        <>
                          <div className="alert alert-warning padding-10" role="alert">
                            <p> The contact with the email '{state.email}' already exists in the CRM.</p>
                            <p> Once the dealer is created, it will be linked to the already created contact '{state.email}' in the CRM and synced with the portal.</p>
                            <p>Note: Complete submitted contact detail will be replaced in the CRM respect to contact found in the CRM.</p>
                          </div>
                          <button type="button" className="btn btn-success" onClick={() => createDealer(true)} disabled={isLoading}>
                            Create Dealer
                          </button>
                          {/* update contact: true */}
                        </>
                      )
                    ) : (

                      isDealerByEmailFound ? (
                        /**
                         * contact by email not found
                         * dealer by email is found  
                         * dealer by operating name is not found
                         */
                        <>
                          <div className="alert alert-warning padding-10" role="alert">
                            <p> Contact with email '{state.email}' is not found but dealer found. </p>
                            <p> So the contact will be created and linked to the dealer '{state.name}' in the CRM and synced with the portal.</p>
                            {/* update contact: false, update dealer: true */}
                          </div>
                          <button type="button" className="btn btn-success" onClick={() => syncDealer(false, true)} disabled={isLoading}>
                            Sync Dealer
                          </button>
                        </>
                      ) : (

                        /**
                         * contact by email not found
                         * dealer by email is not found  
                         * dealer by operating name is not found
                         */

                        <>
                          <div className="alert alert-warning padding-10" role="alert">
                            <p> Neither the dealer nor the contact with the email '{state.email}' exists in the CRM.</p>
                            <p> Once the dealer and contact are created, they will be linked in the CRM and synced with the portal.</p>
                          </div>
                          <button type="button" className="btn btn-success" onClick={() => createDealer(false)} disabled={isLoading}>
                            Create Dealer
                          </button>
                        </>
                      )
                    )
                  )}
                  {errorsWhileCreation ?
                    <div className="alert alert-danger padding-10" role="alert">
                      <p>Following errors occured while creating dealer in the CRM:</p>
                      <ul>
                        {errorsWhileCreation.map((error) => (
                          <li>{error}</li>
                        ))}
                      </ul>
                    </div>
                    : null}
                </div>

                <div style={{ height: "50px" }}></div>
                <div class="card custom-gradient-card text-black">
                  <div class="card-body">
                    <h5 class="card-title">Below statements are true while creating a dealer on CRM:</h5>
                    <ul class="card-text">
                      <li>The operating name is unique.</li>
                      <li>The email is unique as well, and only one contact can create it.</li>
                      <li>One primary dealer can be created with a single email.</li>
                    </ul>
                  </div>
                </div>
                <div style={{ width: "100%", height: "20px" }}></div>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </ReactModal>
    </React.Fragment >
  );
};

export default PendingAccountReviews;