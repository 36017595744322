import React, { useState } from 'react'
import TostarMessages from '../../../../components/alertMessages/TostarMessages'
import { useDispatch } from 'react-redux';
import { user_login } from '../../../../actions/authActions';

import { Link } from 'react-router-dom';

const Login = (props) => {

  const dispatch = useDispatch()

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      // Remove spaces from the email value before setting it
      setEmail(value.trim());
      setEmailError(''); // Clear the email error when the input changes
    }
    if (name === 'password') {
      // Remove spaces from the password value before setting it
      setPassword(value.trim());
      setPasswordError(''); // Clear the password error when the input changes
    }
  };

  const handleSubmit = (e) => {
    
    e.preventDefault();

    if (!email) {
      setEmailError('Please provide an email.');
    } else if (!isValidEmail(email)) {
      setEmailError('Invalid email format.');
    } else if (!password) {
      setPasswordError('Please provide a password.');
    } else {
      const data = {
        email,
        password,
      };
      dispatch(user_login(data));
    }
  }

  return (
    <React.Fragment>
      <section className="Section-ListandGrid p-0">
        <div className="Addpost-responsiveimg bannerhide-mobile">
          <img
            className="w-100"
            src="/assets/image/signup-responsive-img.png"
            alt=""
          />
          <div className="UserRegister-SignUp-r">
            <h1>
              Buy, sell and get financing
              <br /> with a click of a button.
            </h1>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                  <div className="Modal-SignInForm">
                    <div className="clearfix pl-5">
                      <div className="Verification-Head">
                        <h1>Sign In</h1>

                        <form onSubmit={handleSubmit}>

                          <div className="row MobileSignUp-Container">
                            <div className="SignIn-Con">
                              <div className="Register-Form">
                                <label>Email Address</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="email"
                                  placeholder="Email Address"
                                  onChange={(e) => handleChange(e)}
                                />
                                {emailError && <div className="error-message">{emailError}</div>}
                              </div>
                            </div>

                            <div className="SignIn-Con">
                              <div className="Register-Form mb-0">
                                <label>Password</label>
                                <input
                                  type="password"
                                  className="form-control"
                                  name="password"
                                  placeholder='Password'
                                  onChange={(e) => handleChange(e)}
                                />
                                {passwordError && <div className="error-message">{passwordError}</div>}

                              </div>
                              <div className="forgetpass">
                                <Link to='/forgot-password'>Forgot password?</Link>
                              </div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                              <div className="CreatAccount-Btn">
                                <button type="submit"  >
                                  {props.isLoading === true ? (<i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>) : 'Sign in'}
                                </button>
                              </div>
                            </div>

                          </div>
                        </form>
                        <p style={{ width: "439px", fontSize: '18px' }}>
                          Don't have an account? <Link to="/register/seller-signup" style={{ textDecoration: "none" }}><b>Sign Up</b></Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 col-12 pr-0 Dealer-Mobileimg">
                  <div className="Sec-SelectAccount">
                    <div className="SelectText">
                      <h1>
                        Buy, sell and get financing
                        <br /> with a click of a button.
                      </h1>
                    </div>
                    <img src="/assets/image/select-img-1.png" alt="" />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <TostarMessages />
      </section>
    </React.Fragment>
  );
}
export default Login