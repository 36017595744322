let Live_Url = "https://financethat.com/api";
let Flinks_Url =
    "https://financethat-iframe.private.fin.ag/?redirectUrl=https://flinks.com/contact/thank-you&innerRedirect=true&theme=light&consentEnable=true&customerName=FinTech&backgroundColor=f7f7f7&foregroundColor1=000000&desktopLayout=true&headerEnable=false&institutionFilterEnable=true";
let Re_Captcha_key = process.env.REACT_APP_RE_CAPTCHA_KEY;

export const API_URL = "https://financethat.com/api";
export const V1API_FINANCETHAT="https://financethat.com/api";
export const IMAGE_URL = "https://images.financethat.ca";
export const CLIENT_URL = "https://financethat.com";
export const Flinks = Flinks_Url;
export const Captcha_key = Re_Captcha_key;

export const DEALER_BUTTON_APPLICATION_URL = "https://financethat.com";

export const RETAIL_PROGRAM_APP_URL="https://financethat.com/retail"

const gaIds = {
	landing_page: "GTM-NK9855",
	applynow_page: "G-R855554F"
}

export { gaIds };

export const CATEGORY_TYPES = {
  AUTOMOTIVE: 7,
  CONSTRUCTION: 10,
  GOLF_CARTS: 20,
  LAWN_TRACTOR: 8,
  MARINE: 3,
  MOTORCYCLE: 19,
  POWERSPORT: 9,
  RV: 6,
  SMALL_EQUIPMENT: 21,
  TRAILER: 1,
};

export const USER_TYPE = {
  GENERAL_USER: 1,
  DEALER_USER: 2,
  ADMIN: 3,
  MANAGER: 4,
  AGENT: 5,
};
