/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { TextInput, TextMaskedInput } from "../formInputs";
import * as Yup from "yup";
import { save_post_application } from "../../../../actions/postApplication";
export default function EmailDetails({ props, formState, setFormState, onContinue }) {

  const { otp_loading } = useSelector(
    (state) => state.authReducer.authentication
  );
  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const { user_id } = useSelector(
    (state) => state.authReducer.authentication.user
  );

  const { isLoadingOnConfrim } = useSelector(
    (state) => state.authReducer.registration
  );
  const { isLoading, save_post } = useSelector(
    (state) => state.postApplication.postApplicationReducer
  );

  // dispatch fun
  const dispatch = useDispatch();

  // validation Schema
  const ValidationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    telephone: Yup.string().required(),
  });


  // onSubmit
  const onSubmit = (formValues) => {
    setFormState((prev) => {
      return { ...prev, ...formValues, applicant_email: formValues.email };

    });
    const data = {
      ...formState,
      user: user_id || -1,
      applicant_email: formValues.email,
      application_type: formState?.stock ? 2 : 1,
      application_status: 2,
      telephone: formValues.telephone,
      phone: formValues.telephone,
    };
    data.getFinancingAddPost = "yes"
    dispatch(save_post_application(data, () => onContinue()));
  };

  return (
    <div className="formWrapper">
      <h1>What is your email address and phone number?</h1>
      <Formik
        initialValues={{
          email: formState.applicant_email || "",
          telephone: formState.telephone || "",
        }}
        validationSchema={ValidationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <div className="optionFlex">
            <Field
              name="email"
              type="email"
              component={TextInput}
              placeholder="Email Address"
            />
            <Field
              mask={[
                "+",
                "1",
                " ",
                "(",
                /[0-9]/i,
                /[0-9]/i,
                /[0-9]/i,
                ")",
                " ",
                /[0-9]/,
                /[0-9]/i,
                /[0-9]/,
                "-",
                /[0-9]/,
                /[0-9]/i,
                /[0-9]/,
                /[0-9]/,
              ]}
              guide={false}
              name="telephone"
              component={TextMaskedInput}
              placeholder="Phone Number"
            />
            <div style={{ marginTop: "20px" }} className="DealerShip-Con d-flex">
              <label class="containerr">  {"By clicking Submit, I agree to the  "}
                <a
                  href="https://www.financethat.ca/assets/documents/Borrower-Terms-Conditions.pdf"
                  target="_blank" rel="noreferrer"
                >
                  Borrower Terms and Conditions
                </a>
                <span class="checkmarkk"></span>
              </label>
            </div>
          </div>
          <button
            disabled={isLoadingOnConfrim || isLoading || otp_loading}
            type="submit"
            className="primaryButton continueBtn"
          >
            {(otp_loading || isLoadingOnConfrim) ? (
              <>
                <span> </span>
                <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                <span> </span>
              </>
            ) : (
              <>
                <span></span> <span>Submit</span>
                <img
                  src="/assets/image/arrow_circle_right_outline.svg"
                  alt="icon"
                />
              </>
            )}
          </button>

        </Form>
      </Formik>
    </div>
  );
}
