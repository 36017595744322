/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState ,useEffect} from 'react'
const TypesofDealers = (props) => {
  const [vehicles, setVehicles] = useState([{ title: "Automotive", name: "automotive", value: false, subTypes:[{title: "Truck", name: "truck", value: false, },{title: "SUV", name: "suv", value: false },{title: "Van", name: "van", value: false },{title: "Cars", name: "cars", value: false }] },
  { title: "Powersport", name: "powersport", value: false, subTypes:[{title: "Snowmobile", name: "snowmobile", value: false, },{title: "ATV/UTV", name: "atv/utv", value: false, },{title: "Motorcycle", name: "motorcycle", value: false, },{title: "Dirt Bike", name: "dirt_bike", value: false, },{title: "E-Scooter", name: "e-scooter", value: false}] },
  { title: "Marine", name: "marine", value: false, subTypes:[{title: "Personal Watercarft", name: "personal_watercarft", value: false, },{title: "Boat", name: "boat", value: false }] },
  { title: "RV", name: "rv", value: false, subTypes:[{title: "Motor Home", name: "motor_home", value: false, },{title: "Travel Trailer", name: "travel_trailer", value: false }] },
  { title: "Trailer", name: "trailer", value: false, subTypes:[] },
  { title: "Lawn Tractor", name: "lawn_tractor", value: false, subTypes:[] },{ title: "Construction", name: "construction", value: false, subTypes:[{title: "Skid Steer", name: "skid_steer", value: false, },{title: "Backhoe", name: "backhoe", value: false },{title: "Mini Excavator", name: "mini_excavator", value: false },{title: "Loader", name: "loader", value: false },{title: "Farm Tractor", name: "farm_tractor", value: false },{title: "Small Construction", name: "small_construction", value: false }] }])

    const updateVehicle = (name) => {
      setVehicles(vehicles.map(ite => {
        if (ite.name === name) {
          return {
            ...ite,
            value: !ite.value
          }
        }
        return ite
      }))
      let vehicleObject = {}
      vehicles.map(ite => {
        if (ite.name === name) {
          return {
            ...ite,
            value: !ite.value
          }
        }
        return ite
      }).map(item => {
        vehicleObject = {
          ...vehicleObject,
          [item.name]: item.value
        }
      })
        props.changeDealerTypeVehicle("dealer_type_vehicle",vehicleObject)
      }

    const updateSubVehicle = (vehicleName, subVehicleName) => {
        setVehicles(vehicles.map(item => {
          if (item.name === vehicleName) {
            return {
              ...item,
              subTypes: item.subTypes.map(ite=> {
                if(ite.name === subVehicleName){
                  return {
                    ...ite,
                    value:!ite.value
                  }
                }
                return ite

              })
            }
          }
          return item
        }))
        let subVehicleObject = {}
        vehicles.map(item => {
          if (item.name === vehicleName) {
            return {
              ...item,
              subTypes: item.subTypes.map(ite=> {
                if(ite.name === subVehicleName){
                  return {
                    ...ite,
                    value:!ite.value
                  }
                }
                return ite

              })
            }
          }
          return item
        }).map(item => {
          item.subTypes.map(ite=> {
            subVehicleObject = {
              ...subVehicleObject,
              [ite.name]: ite.value,
            }
          })
        })
        props.changeDealerTypeVehicle("dealer_subtype_vehicle",subVehicleObject)

      }

      useEffect(() => {
        if (props.dealer_type_vehicle && Object.keys(props.dealer_type_vehicle).length > 0) {
          setVehicles(vehicles.slice().map(item => {
            if (Object.keys(props.dealer_type_vehicle).includes(item.name)) {
              return {
                ...item,
                value: props.dealer_type_vehicle[item.name],
                subTypes:item.subTypes.map(ite => {
                  if (Object.keys(props.dealer_subtype_vehicle).includes(ite.name)) {
                    return {
                      ...ite,
                      value: props.dealer_subtype_vehicle[ite.name],
                    }
                  }
                  return ite
                  })
              }
            }
            return item
          }))
        }

      }, [props.dealer_type_vehicle])
    return (
        <>  <div className="Addpost-responsiveimg bannerhide-mobile">
        <img className="w-100" src="/assets/image/dealer-img-1.png" alt="" />
    </div>

    <div className="container-fluid vehical">
        <div className="row">

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="row">
                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                        <div className="RegisterAccount-Container">

                            <div className="Select-TopHead pl-0">
                                <h3>Dealership Type</h3>
                                <p>Please select the type of dealership.you can select more then one option.
                              </p>

                            </div>
                            <div className='row'>
          {/* {(vehicles || []).map((item, index) => (

            <div className="col-lg-5" key={index}>
              <div className="row my-2">
                <div className="col-4 swich__title swich__title2 important_c">
                  <label class="label dealer__titlee"> {item.title} </label>
                </div>
                <div className="col-md-2 mbl_view">
                  <div class="switch-holder">
                    <input
                      id={item.name}
                      type="checkbox"
                      name={item.name}
                      checked={item.value}
                      onChange={() => updateVehicle(item.name)}
                    />
                    <label for={item.name} class="switch">
                      <div></div>
                    </label>
                  </div>
                </div>
              </div>

            </div>

           )
          )} */}
              <div className="lllll">  {(vehicles || []).map((item, index) => (
<div style={{height:"400px"}} className={item.value === true || item.subTypes.filter(ite=> ite.value === true).length > 0 ? "v_cards update_vel_colr v_width" : "v_cards v_width gray_clr"} key={index}>
              <div className="sel_v">
                <div className="">
                  <label class="label"> {item.title} </label>
                </div>
                <div className="">
                  <div class="switch-holder">
                    <input
                      id={item.name}
                      type="checkbox"
                      name={item.name}
                      checked={item.value}
                      onChange={() => updateVehicle(item.name)}
                    />
                    <label for="" class="switch">
                      <div></div>
                    </label>
                  </div>
                </div>
              </div>
              {(item.subTypes || []).map((itemSub, subIndex)=> (
                 <div className="sel_sb_type" key={subIndex}>
                 <div className="kikk">
                   <label class="label">{itemSub.title}</label>
                 </div>
                 <div className="">
                   <div class="switch-holder">
                     <input
                      id={itemSub.name}
                      type="checkbox"
                      name={itemSub.name}
                      checked={itemSub.value}
                      onChange={() => updateSubVehicle(item.name, itemSub.name)}
                     />
                     <label for="" class="switch">
                       <div></div>
                     </label>
                   </div>
                 </div>
               </div>
              ))}
            </div>

          ))}</div>
          </div>
          <div className="SignIn-Con w-auto ml-0 mt-5">
                                <div className="DealerForm float-right">

                                    <button
                                      className="btnbg" onClick={()=> {props.change_dealer_type(1)
                                        }
                                      }>
                                      Next
                                      <i className="fa fa-angle-right"></i>
                                    </button>
                                </div>
                              </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 col-12 pr-0 Dealer-Mobileimg">
                        <div className="Sec-SelectAccount">
                            <img src="/assets/image/dealer-img-1.png" alt="Dealer Pic"/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div></>

    )
}
export default TypesofDealers