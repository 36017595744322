import React, { useState } from 'react'

import { CLIENT_URL, DEALER_BUTTON_APPLICATION_URL } from '../../../constant'

const MultilineTextWithCopyButton = ({ multilineText }) => {

  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    const textArea = document.createElement('textarea');
    textArea.value = multilineText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div>
      <div
        style={{
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          border: '1px solid #ddd',
          padding: '10px',
          marginBottom: '20px',
        }}
      >
        {multilineText}
      </div>

      <button
        onClick={handleCopyClick}
        disabled={copied}
        style={{
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          padding: '10px 20px',
          cursor: 'pointer',
        }}
      >
        {copied ? 'Copied!' : 'Copy to Clipboard'}
      </button>

    </div>
  );
};

const ButtonEditor = () => {

  let username = localStorage.getItem("user_alias")

  /**
   * In order to use the button script, you need to include the script tag in your html page.  
   * The script tag has the following attributes: 
   * data-targetElementId: the id of the element where the button will be rendered.
   * data-href: the url of the dealer application.
   * data-text: the text of the button.
   * data-display: the display property of the button.
   * data-padding: the padding of the button.
   * data-background-color: the background color of the button.
   * data-color: the color of the button.
   * data-border: the border of the button.
   * data-border-radius: the border radius of the button.
   * data-text-decoration: the text decoration of the button.
   * data-cursor: the cursor of the button.
   * 
   */

  const jsCode =
    `
  <script
    src="${CLIENT_URL}/assets/js/affiliate.js"
    data-href="${DEALER_BUTTON_APPLICATION_URL}/dealer-apps/${username}"
    data-bottom = '25px';
    data-left = '25px';
    data-mobileBottom = '10px';
    data-mobileLeft = '10px';
    ></script>
      `;
  return (
    <div>
      <MultilineTextWithCopyButton multilineText={jsCode} />
      <pre>
        <code>
          {/* { jsCode} */}
        </code>
      </pre>
    </div>
  );
};

const LeadGeneratorComponent = props => {
  return (
    <React.Fragment>
      <div className="Admin-MainHead">
        <div className="Admin-HeadLeft">
          <h3>Lead Button</h3>
        </div>
        <div className="Admin-HeadRight"></div>
      </div>
      <div className="clearfix"></div>
      <div className="Admin-DealerSetting">
        <div className="InnerDealer-Container">
          <div class="form-group row">
            <div class="col-sm-12">
              <p className='settingMenagetext'>Copy the button script below in the &lt;head&gt; section of your website:</p>
              <ButtonEditor />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LeadGeneratorComponent